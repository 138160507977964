define('fakenews/storages/user-plants', ['exports', 'ember-local-storage/local/array'], function (exports, _emberLocalStorageLocalArray) {

   var Storage = _emberLocalStorageLocalArray['default'].extend();

   //Uncomment if you would like to set initialState
   /*Storage.reopenClass({
   initialState() {
      return [];
      }
   });*/

   exports['default'] = Storage;
});
define('fakenews/controllers/play/office', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    goals: _ember['default'].inject.service(),
    actions: {
      doneWithIntroduction: function doneWithIntroduction() {
        var newSite = this.get('store').createRecord('site');
        newSite.set('game', this.get('gameData.currentGame'));

        newSite.save();
        this.transitionToRoute('play.site.create', newSite);
        this.get('goals').introduceFirstGoal();
      }
    }
  });
});
define("fakenews/templates/head", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "fakenews/templates/head.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("meta");
        dom.setAttribute(el1, "property", "og:title");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("meta");
        dom.setAttribute(el1, "property", "og:url");
        dom.setAttribute(el1, "content", "http://www.fakeittomakeitgame.com/");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("meta");
        dom.setAttribute(el1, "property", "og:image");
        dom.setAttribute(el1, "content", "http://www.fakeittomakeitgame.com/images/game_logo.png");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("meta");
        dom.setAttribute(el1, "property", "og:description");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("meta");
        dom.setAttribute(el1, "property", "fb:app_id");
        dom.setAttribute(el1, "content", "1916503958583426");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("meta");
        dom.setAttribute(el1, "name", "description");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("meta");
        dom.setAttribute(el1, "name", "viewport");
        dom.setAttribute(el1, "content", "width=device-width, initial-scale=1");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [6]);
        var element2 = dom.childAt(fragment, [10]);
        var morphs = new Array(3);
        morphs[0] = dom.createAttrMorph(element0, 'content');
        morphs[1] = dom.createAttrMorph(element1, 'content');
        morphs[2] = dom.createAttrMorph(element2, 'content');
        return morphs;
      },
      statements: [["attribute", "content", ["subexpr", "t", ["meta.title"], [], ["loc", [null, [null, null], [1, 53]]], 0, 0], 0, 0, 0, 0], ["attribute", "content", ["subexpr", "t", ["meta.description"], [], ["loc", [null, [null, null], [4, 65]]], 0, 0], 0, 0, 0, 0], ["attribute", "content", ["concat", [["subexpr", "t", ["meta.description"], [], ["loc", [null, [7, 34], [7, 58]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});
define('fakenews/components/theme-options', ['exports', 'ember', 'ember-cli-pagination/computed/paged-array'], function (exports, _ember, _emberCliPaginationComputedPagedArray) {
  exports['default'] = _ember['default'].Component.extend({
    page: 1,
    perPage: 4,
    totalPagesBinding: "pagedContent.totalPages",

    hasMultiplePages: _ember['default'].computed.gt('pagedContent.totalPages', 1),

    pagedContent: (0, _emberCliPaginationComputedPagedArray['default'])('themeOptions', { pageBinding: "page", perPageBinding: "perPage" }),

    themeOptions: [],

    didReceiveAttrs: function didReceiveAttrs() {
      var self = this;
      _ember['default'].$.getJSON('../../../data/themeOptions.json').then(function (data) {
        var themeOptions = data.uniq();
        self.set('themeOptions', themeOptions);
        self.updateOptions();
      });
    },
    updateOptions: function updateOptions() {
      var themeOptions = this.get('themeOptions');
      var purchasedItemKeys = this.get('gameData.currentGame.purchasedSiteItems');
      themeOptions.forEach(function (option) {
        if (purchasedItemKeys.includes(option.key)) {
          _ember['default'].set(option, 'description', option.alreadyPurchasedDescription);
          _ember['default'].set(option, 'oneTimeCost', 0);
        } else if (option.oneTimeCost > this.get('gameData.currentGame.revenue') && option.oneTimeCost > 0) {
          _ember['default'].set(option, 'tooExpensive', true);
        }
        if (_ember['default'].isPresent(this.get('site'))) {
          if (option.key === this.get('site.theme.key')) {
            _ember['default'].set(option, 'selected', true);
          }
        }
      }, this);
      this.set('themeOptions', themeOptions);
    },
    actions: {
      optionSelected: function optionSelected(option) {
        if (!option.tooExpensive) {
          this.get('themeOptions').forEach(function (option) {
            _ember['default'].set(option, 'selected', false);
          });

          var site = this.get('site');

          site.set('theme', option);
          site.save();

          this.sendAction('themeOptionSelected', option);
          this.updateOptions();
        }
      }
    }
  });
});
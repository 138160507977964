define('fakenews/components/join-groups', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    groupsService: _ember['default'].inject.service('groups'),
    actions: {
      requestMembership: function requestMembership(group) {
        if (this.get('gameData.currentProfile.groups').includes(group)) {
          this.sendAction('itemSelected', group);
        } else {
          this.get('groupsService').requestMembership(group);
        }
      }
    }
  });
});
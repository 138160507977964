define('fakenews/router', ['exports', 'ember', 'fakenews/config/environment'], function (exports, _ember, _fakenewsConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _fakenewsConfigEnvironment['default'].locationType,
    rootURL: _fakenewsConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('not-found', { path: '/*path' });
    this.route('about');

    this.route('play', function () {
      this.route('initial');
      this.route('office');
      this.route('sites');

      this.route('site', { path: "site/:site_id" }, function () {
        this.route('share');
        this.route('copy-articles');
        this.route('write-article');
        this.route('home');

        this.route('create');
      });
    });
  });

  Router.reopen({
    notifyGoogleAnalytics: (function () {
      return ga('send', 'pageview', {
        'page': this.get('url'),
        'title': this.get('url')
      });
    }).on('didTransition')
  });

  exports['default'] = Router;
});
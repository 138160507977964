define('fakenews/adapters/application', ['exports', 'ember-data', 'fakenews/config/environment'], function (exports, _emberData, _fakenewsConfigEnvironment) {
  exports['default'] = _emberData['default'].JSONAPIAdapter.extend({
    //host: ENV.APP.apiURL,
    //namespace: 'api',

    namespace: 'data/api',

    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      return this._buildURL(modelName, id, snapshot, requestType, query) + '.json';
    }
  });
});
define('fakenews/controllers/about', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      closeAbout: function closeAbout() {
        if (_ember['default'].isPresent(this.get('gameData.currentGame'))) {
          this.transitionToRoute('play.sites');
        } else {
          this.get('gameData').createNewGame();
        }
      }
    }
  });
});
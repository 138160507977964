define('fakenews/services/trending-tags', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    gameData: _ember['default'].inject.service(),

    createNewTrendingTags: function createNewTrendingTags() {
      var self = this;
      this.get('store').findAll('tag').then(function (tags) {
        return self.fillTrendingTags([], tags);
      });
    },

    updateTrendingTags: function updateTrendingTags() {
      var _this = this;

      if (this.get('gameData.currentGame.trendingTopicsEnabled')) {
        (function () {
          var self = _this;
          _this.get('store').findAll('tag').then(function (tags) {
            var currentTags = self.get('gameData.currentGame.trendingTags');
            var tagToRemove = currentTags.objectAt(Math.floor(Math.random() * currentTags.get('length')));
            currentTags.removeObject(tagToRemove);
            self.fillTrendingTags(currentTags, tags);
            self.get('gameData.currentGame').save();
          });
        })();
      }
    },

    fillTrendingTags: function fillTrendingTags(trendingTags, allTags) {
      var totalTags = allTags.get('length');

      var count = 0;

      while (trendingTags.get('length') < 3) {

        var potentialTagId = Math.floor(Math.random() * totalTags);
        var potentialTag = allTags.objectAt(potentialTagId);

        if (potentialTag.get('drama') > 5 && !this.containsTag(potentialTag, trendingTags)) {
          trendingTags.addObject(potentialTag);
        }

        count++;

        if (count > 20) {
          break;
        }
      }

      return trendingTags;
    },

    containsTag: function containsTag(obj, list) {
      var i;
      for (i = 0; i < list.length; i++) {
        if (list[i].get('name') === obj.get('name') && list[i].get('party') === obj.get('party')) {
          console.info(list[i].get('name') + " is the same as " + obj.get('name'));
          return true;
        }
      }
      return false;
    }
  });
});
define('fakenews/components/write-article', ['exports', 'ember', 'fakenews/mixins/has-help-modal', 'fakenews/mixins/has-time-passing-modal'], function (exports, _ember, _fakenewsMixinsHasHelpModal, _fakenewsMixinsHasTimePassingModal) {
  exports['default'] = _ember['default'].Component.extend(_fakenewsMixinsHasHelpModal['default'], _fakenewsMixinsHasTimePassingModal['default'], {

    notifications: _ember['default'].inject.service('notification-messages'),
    heartbeat: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    helpTextPath: 'help.writeArticles',
    helpDisplayedVariable: _ember['default'].computed.alias('gameData.currentGame.hasDisplayedWriteArticlesHelp'),

    didInsertElement: function didInsertElement() {
      if (!this.get('helpDisplayedVariable')) {
        $('.helpup').popup({
          on: 'manual',
          position: 'bottom right',
          target: '.help.icon',
          offset: 5,
          distanceAway: 10,
          title: this.get('i18n').t('help.prompt'),
          content: this.get('i18n').t('help.write_prompt'),
          delay: {
            show: 600
          }
        }).popup('show');
      }
    },

    hasBases: _ember['default'].computed.gt('article.articleBases.length', 0),
    hasSupports: _ember['default'].computed.gt('article.articleSupports.length', 0),
    hasTitle: _ember['default'].computed.notEmpty('article.title'),

    readyToWrite: _ember['default'].computed.and('hasBases', 'hasTitle'),

    unaddedSupports: _ember['default'].computed.setDiff('supports', 'article.articleSupports'),
    unselectedBases: _ember['default'].computed.setDiff('bases', 'article.articleBases'),

    timeRequired: _ember['default'].computed('article.articleSupports', 'article.articleBases', function () {
      return this.get('article.articleSupports.length') + this.get('article.articleBases.length') * 2;
    }),

    selectedSupportTags: _ember['default'].computed('article.articleSupports.@each.tags', function () {
      var tags = [];
      this.get('article.articleSupports').forEach(function (support) {
        support.get('tags').forEach(function (tag) {
          tags.pushObject(tag);
        });
      });
      return tags;
    }),

    selectedBaseTags: _ember['default'].computed('article.articleBases.@each.tags', function () {
      var tags = [];
      this.get('article.articleBases').forEach(function (base) {
        base.get('tags').forEach(function (tag) {
          tags.pushObject(tag);
        });
      });
      return tags;
    }),

    hiddenSelectedBaseTags: _ember['default'].computed('article.articleBases.@each.hiddenTags', function () {
      var tags = [];
      this.get('article.articleBases').forEach(function (base) {
        base.get('hiddenTags').forEach(function (tag) {
          tags.pushObject(tag);
        });
      });
      return tags;
    }),

    uniqueTags: _ember['default'].computed.uniq('selectedBaseTags', 'selectedSupportTags'),

    // After this - if two tags have the same category, then the category with the highest drama should stay

    revealedTags: _ember['default'].computed('article', 'uniqueTags', 'article.articleSupports', function () {
      var uniqueTags = this.get('uniqueTags');
      // Add in the non-matching type tags
      var selectedSupports = this.get('article.articleSupports').filterBy('matching', false);
      selectedSupports.forEach(function (support) {
        var tags = support.get('tags');
        tags.forEach(function (tag) {
          uniqueTags.addObject(tag);
        });
      });
      var uniqueCategories = uniqueTags.mapBy('subject').uniq();
      var revealedTags = uniqueTags;

      if (!(uniqueTags.get('length') === uniqueCategories.get('length'))) {
        var highestDramaTags = [];
        for (var i = 0; i < uniqueCategories.get('length'); i++) {
          var categoryTags = uniqueTags.filterBy('subject', uniqueCategories[i]);
          var sortedByDramaTags = categoryTags.sortBy('drama');
          highestDramaTags.push(sortedByDramaTags.slice(-1)[0]);
        }
        revealedTags = highestDramaTags;
      }
      if (_ember['default'].isPresent(this.get('article'))) {
        this.set('article.tags', revealedTags);
      }
      return revealedTags;
    }),

    saveArticle: function saveArticle(self) {
      self.closeTimePassingModal();

      var article = self.get('article');
      article.setProperties({
        tags: self.get('revealedTags'),
        saved: true,
        site: self.get('site'),
        lastTouchDate: self.get('gameData.currentGame.date')
      });

      article.save();
      self.sendAction('articleComplete');
    },
    store: _ember['default'].inject.service(),

    //article: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this.get('bases').forEach(function (base) {
        _ember['default'].set(base, 'selected', false);
      });

      if (_ember['default'].isBlank(this.get('article'))) {
        var article = this.get('store').createRecord('user-article');
        this.set('article', article);
      }
    },

    maxBases: 1,
    maxSupports: 4,

    actions: {
      addBase: function addBase(base) {
        if (base.selected) {
          this.send('removeBase', base);
        } else {
          var bases = this.get('article.articleBases');
          if (bases.get('length') < this.get('maxBases')) {
            bases.pushObject(base);

            bases.forEach(function (base) {
              _ember['default'].set(base, 'selected', false);
            });
            _ember['default'].set(base, 'selected', true);

            if (bases.get('length') === this.get('maxBases')) {
              $('.ui.accordion').accordion('open', 1);
            }
          } else {
            this.get('notifications').warning(this.get('i18n').t('write_article.too_many_bases'), {
              autoClear: true,
              clearDuration: 3600
            });
          }
        }
      },
      removeBase: function removeBase(base) {
        _ember['default'].set(base, 'selected', false);

        this.get('article.articleBases').removeObject(base);
        $('.ui.accordion').accordion('open', 0);
      },
      doneWithSupports: function doneWithSupports() {
        $('.ui.accordion').accordion('open', 2);
      },

      doneWithTitle: function doneWithTitle() {
        $('.ui.accordion').accordion('open', 3);
      },

      addSupport: function addSupport(support) {
        var supports = this.get('article.articleSupports');
        if (supports.get('length') < this.get('maxSupports')) {
          supports.pushObject(support);
          if (supports.get('length') === this.get('maxSupports')) {
            $('.ui.accordion').accordion('open', 2);
          }
        } else {
          this.get('notifications').warning(this.get('i18n').t('write_article.too_many_bases'), {
            autoClear: true,
            clearDuration: 3600
          });
        }
      },
      removeSupport: function removeSupport(support) {
        this.get('article.articleSupports').removeObject(support);
        $('.ui.accordion').accordion('open', 1);
      },

      startWritingArticle: function startWritingArticle() {
        this.get('heartbeat').startSpeedHeartbeat(this.get('timeRequired'), this.saveArticle, this);
        this.openTimePassingModal(this.get('i18n').t('write_article.writing_article'), "");
      }
    }
  });
});
define('fakenews/controllers/play/site/share', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  exports['default'] = _ember['default'].Controller.extend({
    done: false,
    selectionsMode: true,

    currentPlant: null,

    planter: _ember['default'].inject.service(),
    plantArticle: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var newPlant;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.get('planter.plantArticle').perform(this.get('gameData.currentArticle'), this.get('gameData.currentProfile'), this.get('gameData.currentGroup'));

          case 2:
            newPlant = context$1$0.sent;

            this.set('currentPlant', newPlant);

            this.set('selectionsMode', false);

          case 5:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    resetSelectionsMode: function resetSelectionsMode() {
      this.setProperties({
        selectionsMode: true,
        currentPlant: null,
        done: false
      });
      this.get('gameData').resetSelectionsToNull();
    },
    actions: {
      submitSelections: function submitSelections() {
        this.get('plantArticle').perform();
      },
      resetSelectionsMode: function resetSelectionsMode() {
        this.resetSelectionsMode();
      },
      continueClick: function continueClick() {
        this.transitionToRoute('play.site.home');
      },
      bubblesAllDisplayed: function bubblesAllDisplayed() {
        this.set('done', true);
      }
    }
  });
});
define("fakenews/templates/play/initial", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 14
              },
              "end": {
                "line": 16,
                "column": 14
              }
            },
            "moduleName": "fakenews/templates/play/initial.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "photo-card", [], ["option", ["subexpr", "@mut", [["get", "guide", ["loc", [null, [15, 36], [15, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "click", ["subexpr", "action", ["submitGuide", ["get", "guide", ["loc", [null, [15, 70], [15, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [15, 48], [15, 76]]], 0, 0], "class", "ui card"], ["loc", [null, [15, 16], [15, 94]]], 0, 0]],
          locals: ["guide"],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 14
              },
              "end": {
                "line": 40,
                "column": 14
              }
            },
            "moduleName": "fakenews/templates/play/initial.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "photo-card", [], ["option", ["subexpr", "@mut", [["get", "goal", ["loc", [null, [39, 36], [39, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "click", ["subexpr", "action", ["submitGoal", ["get", "goal", ["loc", [null, [39, 68], [39, 72]]], 0, 0, 0, 0]], [], ["loc", [null, [39, 47], [39, 73]]], 0, 0], "class", "ui card"], ["loc", [null, [39, 16], [39, 91]]], 0, 0]],
          locals: ["goal"],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 16
              },
              "end": {
                "line": 52,
                "column": 18
              }
            },
            "moduleName": "fakenews/templates/play/initial.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["new_game.get_started.instructions"], [], ["loc", [null, [51, 21], [51, 62]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child3 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 55,
                  "column": 20
                },
                "end": {
                  "line": 55,
                  "column": 100
                }
              },
              "moduleName": "fakenews/templates/play/initial.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("li");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["new_game.get_started.missing.guide"], [], ["loc", [null, [55, 53], [55, 95]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 56,
                  "column": 20
                },
                "end": {
                  "line": 56,
                  "column": 95
                }
              },
              "moduleName": "fakenews/templates/play/initial.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("li");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["new_game.get_started.missing.name"], [], ["loc", [null, [56, 49], [56, 90]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 20
                },
                "end": {
                  "line": 57,
                  "column": 111
                }
              },
              "moduleName": "fakenews/templates/play/initial.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("li");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["new_game.get_started.missing.goal"], [], ["loc", [null, [57, 65], [57, 106]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 18
              },
              "end": {
                "line": 59,
                "column": 16
              }
            },
            "moduleName": "fakenews/templates/play/initial.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n                  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("ul");
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(element2, 1, 1);
            morphs[2] = dom.createMorphAt(element2, 3, 3);
            morphs[3] = dom.createMorphAt(element2, 5, 5);
            return morphs;
          },
          statements: [["inline", "t", ["new_game.get_started.missing.overall"], [], ["loc", [null, [53, 21], [53, 65]]], 0, 0], ["block", "unless", [["get", "game.guide.imgSrc", ["loc", [null, [55, 30], [55, 47]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [55, 20], [55, 111]]]], ["block", "unless", [["get", "game.userName", ["loc", [null, [56, 30], [56, 43]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [56, 20], [56, 106]]]], ["block", "unless", [["get", "game.gameGoal.longDescription", ["loc", [null, [57, 30], [57, 59]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [57, 20], [57, 122]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 8
            },
            "end": {
              "line": 63,
              "column": 8
            }
          },
          "moduleName": "fakenews/templates/play/initial.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "active title");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "dropdown icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "active content");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "ui link four cards");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "title");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "dropdown icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "content");
          var el2 = dom.createTextNode("\n              ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "ui basic segment");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "ui teal button");
          dom.setAttribute(el3, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "title");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "dropdown icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "content");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "ui blue message");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "ui three link cards");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "title");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "dropdown icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "content");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "ui blue message");
          var el3 = dom.createTextNode("\n              ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "content");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("              ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [7]);
          var element4 = dom.childAt(element3, [3, 1]);
          var element5 = dom.childAt(fragment, [11]);
          var morphs = new Array(11);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3, 1]), 1, 1);
          morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]), 3, 3);
          morphs[3] = dom.createMorphAt(element3, 1, 1);
          morphs[4] = dom.createElementMorph(element4);
          morphs[5] = dom.createMorphAt(element4, 0, 0);
          morphs[6] = dom.createMorphAt(dom.childAt(fragment, [9]), 3, 3);
          morphs[7] = dom.createMorphAt(dom.childAt(element5, [1, 1]), 0, 0);
          morphs[8] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
          morphs[9] = dom.createMorphAt(dom.childAt(fragment, [13]), 3, 3);
          morphs[10] = dom.createMorphAt(dom.childAt(fragment, [15, 1, 1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["new_game.guide_prompt"], [], ["loc", [null, [10, 12], [10, 41]]], 0, 0], ["block", "each", [["get", "model.guides", ["loc", [null, [14, 22], [14, 34]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [14, 14], [16, 23]]]], ["inline", "t", ["new_game.name_prompt"], [], ["loc", [null, [21, 12], [21, 40]]], 0, 0], ["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "game.userName", ["loc", [null, [24, 40], [24, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["new_game.name_placeholder"], [], ["loc", [null, [24, 66], [24, 97]]], 0, 0], "class", "ui big input", "size", "50", "enter", ["subexpr", "action", [["get", "execute", ["loc", [null, [24, 143], [24, 150]]], 0, 0, 0, 0], "open", 2], [], ["loc", [null, [24, 135], [24, 160]]], 0, 0], "autofocus", "autofocus"], ["loc", [null, [24, 14], [24, 184]]], 0, 0], ["element", "action", [["get", "execute", ["loc", [null, [26, 54], [26, 61]]], 0, 0, 0, 0], "open", 2], [], ["loc", [null, [26, 45], [26, 72]]], 0, 0], ["inline", "t", ["labels.next"], [], ["loc", [null, [26, 73], [26, 92]]], 0, 0], ["inline", "t", ["new_game.purchase_prompt"], [], ["loc", [null, [31, 12], [31, 44]]], 0, 0], ["inline", "t", ["new_game.purchase_hint"], [], ["loc", [null, [35, 17], [35, 47]]], 0, 0], ["block", "each", [["get", "model.goals", ["loc", [null, [38, 22], [38, 33]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [38, 14], [40, 23]]]], ["inline", "t", ["new_game.get_started.prompt"], [], ["loc", [null, [45, 12], [45, 47]]], 0, 0], ["block", "if", [["get", "allCompleted", ["loc", [null, [50, 22], [50, 34]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [50, 16], [59, 23]]]]],
        locals: ["execute"],
        templates: [child0, child1, child2, child3]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 69,
              "column": 8
            },
            "end": {
              "line": 71,
              "column": 8
            }
          },
          "moduleName": "fakenews/templates/play/initial.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "left floated tiny ui image");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element1, 'src');
          return morphs;
        },
        statements: [["attribute", "src", ["concat", ["../../images/", ["get", "game.guide.imgSrc", ["loc", [null, [70, 70], [70, 87]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 72,
              "column": 8
            },
            "end": {
              "line": 76,
              "column": 8
            }
          },
          "moduleName": "fakenews/templates/play/initial.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h3");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["new_game.name_summary"], ["userName", ["subexpr", "@mut", [["get", "game.userName", ["loc", [null, [74, 49], [74, 62]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [74, 12], [74, 64]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 77,
              "column": 8
            },
            "end": {
              "line": 79,
              "column": 8
            }
          },
          "moduleName": "fakenews/templates/play/initial.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["new_game.goal_summary"], ["goalLongDescription", ["subexpr", "@mut", [["get", "game.gameGoal.longDescription", ["loc", [null, [78, 61], [78, 90]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [78, 13], [78, 92]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 82,
              "column": 8
            },
            "end": {
              "line": 84,
              "column": 8
            }
          },
          "moduleName": "fakenews/templates/play/initial.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "ui teal fluid button");
          dom.setAttribute(el1, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "ui thumbs up icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 2, 2);
          return morphs;
        },
        statements: [["element", "action", ["submitInitial"], [], ["loc", [null, [83, 47], [83, 73]]], 0, 0], ["inline", "t", ["labels.lets_go"], [], ["loc", [null, [83, 108], [83, 130]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 89,
            "column": 0
          }
        },
        "moduleName": "fakenews/templates/play/initial.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "main container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "ui grid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "eleven wide column");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "ui raised segment");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h1");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "five wide column");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "ui raised segment");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("div");
        dom.setAttribute(el5, "class", "ui clearing hidden divider");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0, 1]);
        var element7 = dom.childAt(element6, [1, 1]);
        var element8 = dom.childAt(element6, [3, 1]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(dom.childAt(element7, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element7, [3]), 0, 0);
        morphs[2] = dom.createMorphAt(element7, 5, 5);
        morphs[3] = dom.createMorphAt(element7, 7, 7);
        morphs[4] = dom.createMorphAt(element8, 1, 1);
        morphs[5] = dom.createMorphAt(element8, 2, 2);
        morphs[6] = dom.createMorphAt(element8, 3, 3);
        morphs[7] = dom.createMorphAt(element8, 7, 7);
        return morphs;
      },
      statements: [["inline", "t", ["new_game.header"], [], ["loc", [null, [5, 12], [5, 35]]], 0, 0], ["inline", "t", ["new_game.music_off"], [], ["loc", [null, [6, 11], [6, 37]]], 0, 0], ["block", "ui-accordion", [], ["class", "fluid"], 0, null, ["loc", [null, [7, 8], [63, 25]]]], ["content", "pause-component", ["loc", [null, [64, 8], [64, 27]]], 0, 0, 0, 0], ["block", "if", [["get", "game.guide.imgSrc", ["loc", [null, [69, 14], [69, 31]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [69, 8], [71, 15]]]], ["block", "if", [["get", "game.userName", ["loc", [null, [72, 14], [72, 27]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [72, 8], [76, 15]]]], ["block", "if", [["get", "game.gameGoal.longDescription", ["loc", [null, [77, 14], [77, 43]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [77, 8], [79, 15]]]], ["block", "if", [["get", "allCompleted", ["loc", [null, [82, 14], [82, 26]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [82, 8], [84, 15]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  })());
});
define('fakenews/models/group', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    gameData: _ember['default'].inject.service(),

    title: _emberData['default'].attr('string'),
    totalNumbers: _emberData['default'].attr('number'),
    tags: _emberData['default'].hasMany('tag'),
    profiles: _emberData['default'].hasMany('profile'),

    userPlants: _emberData['default'].hasMany('user-plant'),
    userProfiles: _emberData['default'].hasMany('user-profile', { inverse: 'groups' }),
    pendingMembers: _emberData['default'].hasMany('user-profile', { inverse: 'pendingGroups' }),
    rejectedMembers: _emberData['default'].hasMany('user-profile', { inverse: 'rejectedGroups' }),

    politicalSlant: _emberData['default'].attr(''),

    alreadyPlanted: _ember['default'].computed('userPlants', 'gameData.currentArticle', function () {
      var alreadyPlanted = false;
      if (_ember['default'].isPresent(this.get('gameData.currentArticle'))) {
        var currentArticle = this.get('gameData.currentArticle');

        if (currentArticle.get('groupsPlantedTo').mapBy('id').includes(this.get('id'))) {
          alreadyPlanted = true;
        }
      }

      return alreadyPlanted;
    })

  });
});
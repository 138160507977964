define("fakenews/components/game-dashboard", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    classNames: ["dashboard", "container"],

    didInsertElement: function didInsertElement() {
      if (this.get('gameData.currentGame.audioOn')) {
        this.$('#player')[0].play();
      }
    },

    actions: {
      openMainMenu: function openMainMenu() {
        this.set('gameData.gamePaused', true);
        var self = this;
        _ember["default"].$('.ui.menu.modal').modal({
          onHidden: function onHidden() {
            self.set('gameData.gamePaused', false);
          }
        }).modal('show');
      },
      turnOnMusic: function turnOnMusic() {
        this.$('#player')[0].play();
        this.set('gameData.currentGame.audioOn', true);
      },
      turnOffMusic: function turnOffMusic() {
        this.$('#player')[0].pause();
        this.set('gameData.currentGame.audioOn', false);
      }
    }

  });
});
define('fakenews/services/groups', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  exports['default'] = _ember['default'].Service.extend({
    notifications: _ember['default'].inject.service('notification-messages'),
    gameData: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    requestMembership: function requestMembership(group) {
      var profile = this.get('gameData.currentProfile');

      this.get('notifications').info(this.get('i18n').t('group.pending_approval', {
        "profile": profile.get('name'),
        "group": group.get('title')
      }), {
        autoClear: true,
        clearDuration: 4800
      });

      profile.get('pendingGroups').addObject(group);
      profile.save();
    },
    permitJoin: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(group, profile) {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return profile.get('pendingGroups').removeObject(group);

          case 2:
            context$1$0.next = 4;
            return profile.get('groups').addObject(group);

          case 4:
            context$1$0.next = 6;
            return profile.save();

          case 6:
            this.get('notifications').success(this.get('i18n').t('group.added', {
              "profile": profile.get('name'),
              "group": group.get('title')
            }), {
              autoClear: true,
              clearDuration: 3600
            });

          case 7:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),
    reject: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(group, profile) {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return profile.get('pendingGroups').removeObject(group);

          case 2:
            context$1$0.next = 4;
            return profile.get('rejectedGroups').addObject(group);

          case 4:
            context$1$0.next = 6;
            return profile.save();

          case 6:
            this.get('notifications').error(this.get('i18n').t('group.rejected', {
              "profile": profile.get('name'),
              "group": group.get('title')
            }), {
              autoClear: true,
              clearDuration: 3600
            });

          case 7:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),
    checkGroups: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var profilesWithPendingGroups, profilesLength, i, profile, pendingGroups, pendingGroupsLength, y, group, chanceReject, groupPoliticalSlant, profilePoliticalSlant;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (!this.get('gameData.currentGame.profilesWithPendingGroups.length')) {
              context$1$0.next = 14;
              break;
            }

            profilesWithPendingGroups = this.get('gameData.currentGame.profilesWithPendingGroups');
            profilesLength = profilesWithPendingGroups.length;
            i = 0;

          case 4:
            if (!(i < profilesLength)) {
              context$1$0.next = 14;
              break;
            }

            profile = profilesWithPendingGroups[i];
            context$1$0.next = 8;
            return profile.get('pendingGroups');

          case 8:
            pendingGroups = context$1$0.sent;
            pendingGroupsLength = pendingGroups.length;

            for (y = 0; y < pendingGroupsLength; y++) {
              group = pendingGroups.objectAt(y);

              if (Math.random() > 0.7) {
                chanceReject = 0;
                groupPoliticalSlant = group.get('politicalSlant');
                profilePoliticalSlant = profile.get('politicalSlant');

                if (groupPoliticalSlant !== 0 && profilePoliticalSlant !== 0) {
                  if (profilePoliticalSlant !== groupPoliticalSlant) {
                    chanceReject += 0.65;
                  }
                }
                if (profilePoliticalSlant === groupPoliticalSlant) {
                  chanceReject -= 0.45;
                }

                if (profile.get('credibility') < 10) {
                  chanceReject += 0.25;
                }

                if (Math.random() + chanceReject < 1) {
                  this.get('permitJoin').perform(group, profile);
                } else {
                  this.get('reject').perform(group, profile);
                }
              }
            }

          case 11:
            i++;
            context$1$0.next = 4;
            break;

          case 14:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    }))

  });
});
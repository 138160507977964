define('fakenews/models/game', ['exports', 'ember-data', 'ember', 'fakenews/mixins/retrieve-nested'], function (exports, _emberData, _ember, _fakenewsMixinsRetrieveNested) {
  exports['default'] = _emberData['default'].Model.extend(_fakenewsMixinsRetrieveNested['default'], {
    moment: _ember['default'].inject.service(),
    goals: _ember['default'].inject.service(),
    money: _ember['default'].inject.service(),

    lastPlayed: _emberData['default'].attr(),

    trendingTopicsEnabled: _emberData['default'].attr('boolean', { defaultValue: false }),
    audioOn: _emberData['default'].attr('boolean', { defaultValue: true }),

    hasWon: _emberData['default'].attr('boolean', { defaultValue: false }),
    hasDisplayedPlantHelp: _emberData['default'].attr('boolean', { defaultValue: false }),
    hasDisplayedCopyArticlesHelp: _emberData['default'].attr('boolean', { defaultValue: false }),
    hasDisplayedWriteArticlesHelp: _emberData['default'].attr('boolean', { defaultValue: false }),
    hasDisplayedFirstArticleDebunked: _emberData['default'].attr('boolean', { defaultValue: false }),
    hasDisplayedFamousShare: _emberData['default'].attr('boolean', { defaultValue: false }),
    hasDisplayedHintMessage: _emberData['default'].attr('boolean', { defaultValue: false }),
    famousShareOn: _emberData['default'].attr('boolean', { defaultValue: false }),
    writeArticlesEnabled: _emberData['default'].attr('boolean', { defaultValue: true }),

    hasDisplayedHateCrimes: _emberData['default'].attr('boolean', { defaultValue: false }),
    hasDisplayedHealth: _emberData['default'].attr('boolean', { defaultValue: false }),
    hasDisplayedCrime: _emberData['default'].attr('boolean', { defaultValue: false }),
    hasDisplayedPersonGotFired: _emberData['default'].attr('boolean', { defaultValue: false }),

    goalsCompleted: _emberData['default'].attr('boolean', { defaultValue: false }),
    showCompletedGoals: _emberData['default'].attr('boolean', { defaultValue: true }),

    revenue: _emberData['default'].attr('number', { defaultValue: 50 }),

    date: _emberData['default'].attr('date', {
      defaultValue: function defaultValue() {
        return new Date("January 1, 2016 00:00:00");
      }
    }),

    dateStartedGoal: _emberData['default'].attr('date', {
      defaultValue: function defaultValue() {
        return new Date("January 1, 2016 00:00:00");
      }
    }),

    articles: _ember['default'].computed('sites.@each.articles', function () {
      return this.retrieveNested(this.get('sites'), 'articles');
    }),

    plants: _ember['default'].computed('sites.@each.plants', function () {
      return this.retrieveNested(this.get('sites'), 'plants');
    }),
    activePlants: _ember['default'].computed.filterBy('plants', 'active'),
    activeArticles: _ember['default'].computed.mapBy('activePlants', 'article'),

    recurringExpense: _emberData['default'].attr({ defaultValue: function defaultValue() {
        return [];
      } }),

    upcomingExpenses: _ember['default'].computed('date', function () {
      var upcomingExpenses = [];
      var monthlyExpenses = this.get('recurringExpense').filterBy('type', 'monthly');
      var yearlyAndLoanExpenses = this.get('recurringExpense').rejectBy('type', 'monthly');

      monthlyExpenses.forEach(function (expense) {
        var month = this.get('date').getMonth();

        if (expense.day < this.get('date').getDate()) {
          month++;
        }
        var date = new Date(this.get('date').getYear(), month, expense.day);

        var newExpense = {
          date: date,
          amount: expense.amount,
          reason: expense.reason
        };

        upcomingExpenses.addObject(newExpense);
      }, this);
      yearlyAndLoanExpenses.forEach(function (expense) {
        var month = this.get('date').getMonth();

        if (expense.day < this.get('date').getDate()) {
          month++;
          if (month === 12) {
            month = 0;
          }
        }
        if (expense.month === month) {
          var date = new Date(this.get('date').getYear(), month, expense.day);

          var newExpense = {
            date: date,
            amount: expense.amount,
            reason: expense.reason
          };

          upcomingExpenses.addObject(newExpense);
        }
      }, this);
      upcomingExpenses = upcomingExpenses.sortBy('date:asc');
      return upcomingExpenses;
    }),

    expenseLog: _emberData['default'].attr({ defaultValue: [] }),

    sortProperties: ['date:desc'],

    sortedExpenseLog: _ember['default'].computed.sort('expenseLog', 'sortProperties'),

    recentExpenses: _ember['default'].computed('sortedExpenseLog', function () {
      return this.get('sortedExpenseLog').slice(0, 3);
    }),

    hoursRemaining: _ember['default'].computed('date', function () {
      return 5 - this.get('date').getHours();
    }),

    hoursRemainingArray: _ember['default'].computed('date', function () {
      var hoursRemainingArray = [];
      var count = 1;
      while (count <= this.get('date').getHours()) {
        hoursRemainingArray.push("");
        count++;
      }
      return hoursRemainingArray;
    }),

    percentGoalComplete: _ember['default'].computed('revenue', function () {
      return Math.floor(this.get('revenue') / this.get('gameGoal.cost') * 100);
    }),

    userName: _emberData['default'].attr('string', { defaultValue: "" }),
    guide: _emberData['default'].attr('', { defaultValue: {} }),
    gameGoal: _emberData['default'].attr('', { defaultValue: {} }),

    hasHosting: _emberData['default'].attr('boolean', { defaultValue: false }),

    purchasedSiteItems: _emberData['default'].attr('', { defaultValue: [] }),

    savedSites: _ember['default'].computed.filterBy('sites', 'saved'),
    sites: _emberData['default'].hasMany('sites', { dependent: 'destroy' }),
    profiles: _emberData['default'].hasMany('user-profile', { dependent: 'destroy' }),

    profilesWithPendingGroups: _ember['default'].computed('profiles.@each.pendingGroups', function () {
      var profiles = this.get('profiles');
      return profiles.rejectBy('pendingGroups.length', 0);
    }),

    trendingTags: _emberData['default'].hasMany('tag', { async: true }),

    canCollectPersonalInformation: _emberData['default'].attr('boolean', { defaultValue: false }),

    activeGoals: _ember['default'].computed('goals.activeGoals', function () {
      return this.get('goals.activeGoals');
    }),

    completedGoals: _ember['default'].computed('allGoals.@each.completed', function () {
      return this.get('allGoals').filterBy('completed', true);
    }),

    expenses: _ember['default'].computed(function () {
      return this.get('money.activeExpenses');
    }),

    allGoals: _emberData['default'].attr('')
  });
});
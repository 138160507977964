define('fakenews/routes/play/site/share', ['exports', 'ember', 'rsvp'], function (exports, _ember, _rsvp) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return _rsvp['default'].hash({
        profiles: this.get('store').findAll('profile'),
        tags: this.get('store').findAll('tag'),
        groups: this.get('store').findAll('group'),

        //userProfiles: this.get('store').findAll('user-profile').filterBy('game', this.get('gameData.currentGame')),
        userPlants: this.get('store').findAll('user-plant'),

        site: this.modelFor('play.site')
      });
    },
    afterModel: function afterModel() {
      this.get('gameData').resetPauseAndModal();
    }
  });
});
define("fakenews/templates/components/guide-message", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "fakenews/templates/components/guide-message.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("img");
        dom.setAttribute(el1, "class", "guide");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n  ");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "text");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("br");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "ui teal button");
        dom.setAttribute(el1, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [8]);
        var morphs = new Array(4);
        morphs[0] = dom.createAttrMorph(element0, 'src');
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        morphs[2] = dom.createElementMorph(element1);
        morphs[3] = dom.createMorphAt(element1, 0, 0);
        return morphs;
      },
      statements: [["attribute", "src", ["subexpr", "concat", ["../../../images/", ["get", "gameData.currentGame.guide.imgSrc", ["loc", [null, [1, 37], [1, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [1, 72]]], 0, 0], 0, 0, 0, 0], ["content", "feedback", ["loc", [null, [3, 4], [3, 16]]], 0, 0, 0, 0], ["element", "action", [["get", "continueClick", ["loc", [null, [7, 40], [7, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 31], [7, 55]]], 0, 0], ["inline", "t", ["labels.continue"], [], ["loc", [null, [7, 56], [7, 79]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});
define('fakenews/components/copy-articles', ['exports', 'ember', 'fakenews/mixins/has-help-modal', 'fakenews/mixins/has-time-passing-modal'], function (exports, _ember, _fakenewsMixinsHasHelpModal, _fakenewsMixinsHasTimePassingModal) {
  exports['default'] = _ember['default'].Component.extend(_fakenewsMixinsHasHelpModal['default'], _fakenewsMixinsHasTimePassingModal['default'], {
    store: _ember['default'].inject.service(),
    totalArticlesSelected: _ember['default'].computed.alias('selectedArticles.length'),
    heartbeat: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    helpTextPath: 'help.copyArticles',
    helpDisplayedVariable: _ember['default'].computed.alias('gameData.currentGame.hasDisplayedCopyArticlesHelp'),

    didReceiveAttrs: function didReceiveAttrs() {
      this.get('uncopiedArticles').forEach(function (article) {
        _ember['default'].set(article, 'selected', false);
      });
    },

    didInsertElement: function didInsertElement() {
      if (!this.get('helpDisplayedVariable')) {
        $('.helpup').popup({
          on: 'manual',
          position: 'bottom right',
          target: '.help.icon',
          offset: 5,
          distanceAway: 10,
          title: this.get('i18n').t('help.prompt'),
          content: this.get('i18n').t('help.copy_prompt'),
          delay: {
            show: 600
          }
        }).popup('show');
      }
    },

    isIncomplete: _ember['default'].computed('totalArticlesSelected', function () {
      return this.get('totalArticlesSelected') === 0;
    }),
    timeRequired: _ember['default'].computed('totalArticlesSelected', function () {
      return this.get('totalArticlesSelected') * 3;
    }),

    selectedArticles: _ember['default'].computed.filterBy('uncopiedArticles', 'selected', true),

    uncopiedArticles: _ember['default'].computed('model.userArticles', function () {
      var copiedArticles = this.get('gameData.currentGame.articles').mapBy('copiedFrom.id');

      var uncopiedArticles = [];

      this.get('model.articles').forEach(function (article) {
        if (!copiedArticles.includes(article.id)) {
          uncopiedArticles.addObject(article);
        }
      });

      return uncopiedArticles;
    }),

    copyArticles: function copyArticles(self) {
      self.closeTimePassingModal();

      var selectedArticles = self.get('selectedArticles');

      selectedArticles.forEach(function (article) {
        var articleData = {
          title: article.get('title'),
          tags: article.get('tags'),
          articleBases: article.get('articleBases'),
          articleSupports: article.get('articleSupports'),
          copiedFrom: article,
          site: self.get('model.site'),
          lastTouchDate: self.get('gameData.currentGame.date')
        };

        var newArticle = self.get('store').createRecord('user-article', articleData);
        newArticle.save();
      });
      self.get('model.site').save();
      self.set('totalArticlesSelected', 0);

      self.sendAction('doneCopying');
    },
    actions: {
      selectArticle: function selectArticle(article) {
        article.toggleProperty('selected');
      },
      startArticleCopy: function startArticleCopy() {
        this.get('heartbeat').startSpeedHeartbeat(this.get('timeRequired'), this.copyArticles, this);
        var totalArticles = this.get('totalArticlesSelected');
        var message = totalArticles === 1 ? this.get('i18n').t('copy_articles.copy_message_one') : this.get('i18n').t('copy_articles.copy_message_multiple', { number: totalArticles });

        this.openTimePassingModal(this.get('i18n').t('copy_articles.copying_articles'), message);
      }
    }
  });
});
define('fakenews/controllers/play/sites', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    actions: {
      newSite: function newSite() {
        var newSite = this.get('store').createRecord('site');
        newSite.save();
        this.transitionToRoute('play.site.create', newSite);
      }
    }
  });
});
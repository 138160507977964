define('fakenews/components/main-menu-modal', ['exports', 'semantic-ui-ember/components/ui-modal', 'ember'], function (exports, _semanticUiEmberComponentsUiModal, _ember) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    name: 'menu',
    classNames: ['menu'],
    routing: _ember['default'].inject.service('-routing'),

    actions: {
      closeModal: function closeModal() {
        $('.ui.menu.modal').modal('hide');
      },
      backToIndex: function backToIndex() {
        this.get("routing").transitionTo("index");
        $('.ui.menu.modal').modal('hide');
      },
      aboutThisGame: function aboutThisGame() {
        this.get("routing").transitionTo("about");
        $('.ui.menu.modal').modal('hide');
      }
    }

  });
});
define('fakenews/routes/play/site/copy-articles', ['exports', 'ember', 'rsvp'], function (exports, _ember, _rsvp) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return _rsvp['default'].hash({
        articles: this.get('store').findAll('article'),
        site: this.modelFor('play.site')
      });
    },
    afterModel: function afterModel() {
      this.get('gameData').resetPauseAndModal();
    }
  });
});
define('fakenews/components/selections-display', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    hasArticleProfileGroup: _ember['default'].computed.and('gameData.currentArticle', 'gameData.currentProfile', 'gameData.currentGroup'),
    actions: {
      plantArticle: function plantArticle() {
        this.sendAction('submitSelections');
      }
    }

  });
});
define("fakenews/templates/play/site/share", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 11,
              "column": 4
            }
          },
          "moduleName": "fakenews/templates/play/site/share.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "plant-selections", [], ["site", ["subexpr", "@mut", [["get", "model.site", ["loc", [null, [5, 15], [5, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "profiles", ["subexpr", "@mut", [["get", "model.profiles", ["loc", [null, [6, 17], [6, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "groups", ["subexpr", "@mut", [["get", "model.groups", ["loc", [null, [7, 15], [7, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "articles", ["subexpr", "@mut", [["get", "model.site.articles", ["loc", [null, [8, 17], [8, 36]]], 0, 0, 0, 0]], [], [], 0, 0], "bubblesAllDisplayed", ["subexpr", "action", ["bubblesAllDisplayed"], [], ["loc", [null, [9, 28], [9, 58]]], 0, 0]], ["loc", [null, [4, 6], [10, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 4
            },
            "end": {
              "line": 20,
              "column": 4
            }
          },
          "moduleName": "fakenews/templates/play/site/share.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "plant-comments", [], ["plant", ["subexpr", "@mut", [["get", "currentPlant", ["loc", [null, [13, 14], [13, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "images", ["subexpr", "@mut", [["get", "model.images", ["loc", [null, [14, 15], [14, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "comments", ["subexpr", "@mut", [["get", "model.comments", ["loc", [null, [15, 17], [15, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "done", ["subexpr", "@mut", [["get", "done", ["loc", [null, [16, 13], [16, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "continueClick", ["subexpr", "action", ["continueClick"], [], ["loc", [null, [17, 24], [17, 48]]], 0, 0], "resetSelectionsMode", ["subexpr", "action", ["resetSelectionsMode"], [], ["loc", [null, [18, 30], [18, 60]]], 0, 0]], ["loc", [null, [12, 6], [19, 8]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 24,
                "column": 8
              },
              "end": {
                "line": 26,
                "column": 8
              }
            },
            "moduleName": "fakenews/templates/play/site/share.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "goals-display", ["loc", [null, [25, 10], [25, 27]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 8
              },
              "end": {
                "line": 29,
                "column": 8
              }
            },
            "moduleName": "fakenews/templates/play/site/share.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["content", "trending-topics", ["loc", [null, [28, 10], [28, 29]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 8
              },
              "end": {
                "line": 40,
                "column": 8
              }
            },
            "moduleName": "fakenews/templates/play/site/share.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "ui raised segment");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("h4");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(element0, 3, 3);
            return morphs;
          },
          statements: [["inline", "t", ["write_an_article.summary.your_selections"], [], ["loc", [null, [32, 16], [32, 64]]], 0, 0], ["inline", "selections-display", [], ["article", ["subexpr", "@mut", [["get", "gameData.currentArticle", ["loc", [null, [34, 22], [34, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "profile", ["subexpr", "@mut", [["get", "gameData.currentProfile", ["loc", [null, [35, 22], [35, 45]]], 0, 0, 0, 0]], [], [], 0, 0], "group", ["subexpr", "@mut", [["get", "gameData.currentGroup", ["loc", [null, [36, 20], [36, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "submitSelections", ["subexpr", "action", ["submitSelections"], [], ["loc", [null, [37, 31], [37, 58]]], 0, 0]], ["loc", [null, [33, 12], [38, 14]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 6
            },
            "end": {
              "line": 41,
              "column": 6
            }
          },
          "moduleName": "fakenews/templates/play/site/share.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "unless", [["get", "gameData.currentGame.goalsCompleted", ["loc", [null, [24, 18], [24, 53]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [24, 8], [26, 19]]]], ["block", "if", [["get", "gameData.currentGame.trendingTopicsEnabled", ["loc", [null, [27, 14], [27, 56]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [27, 8], [29, 15]]]], ["block", "if", [["get", "gameData.currentArticle", ["loc", [null, [30, 14], [30, 37]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [30, 8], [40, 15]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 6
            },
            "end": {
              "line": 46,
              "column": 6
            }
          },
          "moduleName": "fakenews/templates/play/site/share.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "plant-results", [], ["plant", ["subexpr", "@mut", [["get", "currentPlant", ["loc", [null, [43, 16], [43, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "done", ["subexpr", "@mut", [["get", "done", ["loc", [null, [44, 15], [44, 19]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [42, 8], [45, 10]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 49,
            "column": 0
          }
        },
        "moduleName": "fakenews/templates/play/site/share.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ui grid");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "ten wide column");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "six wide column");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element1 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
        return morphs;
      },
      statements: [["block", "if", [["get", "selectionsMode", ["loc", [null, [3, 10], [3, 24]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [3, 4], [20, 11]]]], ["block", "if", [["get", "selectionsMode", ["loc", [null, [23, 12], [23, 26]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [23, 6], [46, 13]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});
define('fakenews/components/guide-modal', ['exports', 'semantic-ui-ember/components/ui-modal'], function (exports, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    name: 'guide',
    classNames: ['guide'],
    actions: {
      closeModal: function closeModal() {
        $('.ui.guide.modal').modal('hide');
      }

    }

  });
});
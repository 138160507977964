define('fakenews/mixins/has-help-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    i18n: _ember['default'].inject.service(),
    actions: {
      openHelp: function openHelp() {
        this.set('helpDisplayedVariable', true);
        this.get('gameData').openModal(this.get('i18n').t(this.get('helpTextPath')));
        $('.helpup').popup('hide');
      }
    }
  });
});
define('fakenews/services/money', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({

    gameData: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    // For all of these, check to see if you have enough money.
    // If not, then check for outstanding loan. If you have an outstanding loan, lose game
    // If you don't have an outstanding loan, have loan dialog pop up

    checkExpenses: function checkExpenses() {
      var recurringExpenses = this.get('gameData.currentGame.recurringExpense');
      recurringExpenses.forEach(function (expense) {
        if (expense.type === 'monthly') {
          if (expense.day === this.get('gameData.currentGame.date').getDate()) {
            this.subtractFunds(expense.amount, expense.reason);
          }
        } else {
          if (expense.month === this.get('gameData.currentGame.date').getMonth() && expense.day === this.get('gameData.currentGame.date').getDate()) {
            this.subtractFunds(expense.amount, expense.reason);
            if (expense.type === 'loan') {
              recurringExpenses.removeObject(expense);
            }
          }
        }
      }, this);
    },

    takeOutLoan: function takeOutLoan(loanAmount, repaymentAmount) {
      this.incrementProperty('gameData.currentGame.revenue', loanAmount);

      var nextMonth = this.get('gameData.currentGame.date').getMonth() + 1;
      if (nextMonth === 12) {
        nextMonth = 0;
      }

      var newExpense = {
        type: 'loan',
        day: this.get('gameData.currentGame.date').getDate(),
        month: nextMonth,
        amount: repaymentAmount,
        reason: this.get('i18n').t('loan.loan_repayment')
      };

      this.get('gameData.currentGame.recurringExpense').addObject(newExpense);
    },

    launchLoanModal: function launchLoanModal() {
      var loanAmount = Math.floor((Math.abs(this.get('gameData.currentGame.revenue')) + 20) / 10) * 10;
      var repaymentAmount = loanAmount + loanAmount / 10;

      this.set('gameData.modalText', this.get('i18n').t('loan.loan_terms', { "loanAmount": loanAmount, "repaymentAmount": repaymentAmount }));

      this.set('gameData.gamePaused', true);
      var self = this;
      $('.ui.guide.modal').modal({
        onHidden: function onHidden() {
          self.set('gameData.gamePaused', false);
          self.takeOutLoan(loanAmount, repaymentAmount);
        }
      }).modal('setting', 'closable', false).modal('show');
    },

    subtractFunds: function subtractFunds(amount, reason) {

      this.decrementProperty('gameData.currentGame.revenue', amount);
      var newExpense = {
        date: this.get('gameData.currentGame.date'),
        amount: amount,
        reason: reason
      };
      this.get('gameData.currentGame.expenseLog').addObject(newExpense);
    },

    addRecurringExpense: function addRecurringExpense(type, amount, reason, immediate) {
      var newExpense = {
        type: type,
        day: this.get('gameData.currentGame.date').getDate(),
        amount: amount,
        reason: reason
      };
      if (type === 'yearly') {
        newExpense.month = this.get('gameData.currentGame.date').getMonth();
      }
      this.get('gameData.currentGame.recurringExpense').addObject(newExpense);

      if (immediate) {
        this.subtractFunds(amount, reason);
      }
    }
  });
});
define("fakenews/components/goals-display", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    classNames: ["ui", "raised", "segment"],
    goals: _ember["default"].inject.service(),
    actions: {
      reviewGoal: function reviewGoal(goal) {
        this.get('goals').reviewGoal(goal);
      },
      toggleShowCompletedGoals: function toggleShowCompletedGoals() {
        this.toggleProperty('gameData.currentGame.showCompletedGoals');
      }
    }
  });
});
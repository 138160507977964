define('fakenews/components/plant-selections', ['exports', 'ember', 'fakenews/mixins/has-help-modal'], function (exports, _ember, _fakenewsMixinsHasHelpModal) {
  exports['default'] = _ember['default'].Component.extend(_fakenewsMixinsHasHelpModal['default'], {
    showHelp: false,
    heartbeat: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    hasCurrentProfile: _ember['default'].computed.notEmpty('gameData.currentProfile'),

    hasArticleProfileGroup: _ember['default'].computed.and('gameData.currentArticle', 'gameData.currentProfile', 'gameData.currentGroup'),

    profileHasGroups: _ember['default'].computed.notEmpty('gameData.currentProfile.groups'),

    profileSelectMode: true,
    profileCreateMode: false,
    profileBuyMode: false,

    userProfiles: _ember['default'].computed.alias('gameData.currentGame.profiles'),

    helpTextPath: 'help.plantArticles',
    helpDisplayedVariable: _ember['default'].computed.alias('gameData.currentGame.hasDisplayedPlantHelp'),

    didInsertElement: function didInsertElement() {
      if (!this.get('helpDisplayedVariable')) {
        $('.helpup').popup({
          on: 'manual',
          position: 'bottom right',
          target: '.help.icon',
          offset: 5,
          distanceAway: 10,
          title: this.get('i18n').t('help.prompt'),
          content: this.get('i18n').t('help.plant_prompt'),
          delay: {
            show: 600
          }
        }).popup('show');
      }
    },

    userProfilesToDisplay: _ember['default'].computed('profileTabSelection', 'userProfiles.@each.saved', function () {
      return this.get('userProfiles').filterBy('saved').reverseObjects();
    }),

    articlesToDisplay: _ember['default'].computed('articles', function () {
      return this.get('articles').filterBy('saved').reverseObjects();
    }),

    hasUserProfiles: _ember['default'].computed.gt('userProfilesToDisplay.length', 0),

    uncopiedProfiles: _ember['default'].computed('userProfiles', function () {
      var copiedProfiles = this.get('userProfiles').mapBy('copiedFrom.id');

      var uncopiedProfiles = [];

      this.get('profiles').forEach(function (profile) {
        if (!copiedProfiles.includes(profile.id)) {
          uncopiedProfiles.addObject(profile);
        }
      });

      return uncopiedProfiles;
    }),
    unjoinedGroups: _ember['default'].computed('gameData.currentProfile', function () {
      var self = this;

      var unjoinedGroups = this.get('groups').filter(function (group) {
        return !group.get('userProfiles').includes(self.get('gameData').currentProfile);
      });

      return unjoinedGroups.sortBy('politicalSlant');
    }),

    actions: {
      selectArticle: function selectArticle(article) {
        this.get('gameData').selectArticle(article);
        this.get('articlesToDisplay').forEach(function (article) {
          _ember['default'].set(article, 'selected', false);
        });
        _ember['default'].set(article, 'selected', true);

        $('.ui.accordion').accordion('open', 1);
      },
      selectProfile: function selectProfile(profile) {
        this.get('gameData').selectProfile(profile);

        this.get('userProfilesToDisplay').forEach(function (profile) {
          _ember['default'].set(profile, 'selected', false);
        });
        _ember['default'].set(profile, 'selected', true);

        $('.ui.accordion').accordion('open', 2);
      },

      selectGroup: function selectGroup(group) {
        if (!group.get('alreadyPlanted')) {
          this.get('gameData').selectGroup(group);

          this.get('gameData.currentProfile.groups').forEach(function (group) {
            _ember['default'].set(group, 'selected', false);
          });
          _ember['default'].set(group, 'selected', true);

          $('.ui.accordion').accordion('open', 3);
        }
      },

      accordionSelected: function accordionSelected() {
        if (_ember['default'].isPresent(this.get('userProfiles'))) {
          this.set('profileTabSelection', 'ProfilesTabA');
        } else {
          this.set('profileTabSelection', 'ProfilesTabC');
        }
        if (_ember['default'].isPresent(this.get('gameData.currentProfile.groups'))) {
          this.set('groupTabSelection', 'GroupsTabA');
        } else {
          this.set('groupTabSelection', 'GroupsTabB');
        }
      }
    }
  });
});
define('fakenews/routes/play/site', ['exports', 'ember', 'ember-cli-reset-scroll'], function (exports, _ember, _emberCliResetScroll) {
  exports['default'] = _ember['default'].Route.extend(_emberCliResetScroll['default'], {
    model: function model(params) {
      return this.get('store').findRecord('site', params.site_id);
    },
    afterModel: function afterModel(model) {
      this.set('gameData.currentSite', model);
    },
    resetScroll: undefined
  });
});
define('fakenews/mixins/article', ['exports', 'ember', 'ember-data', 'fakenews/mixins/political-slant', 'fakenews/mixins/retrieve-nested'], function (exports, _ember, _emberData, _fakenewsMixinsPoliticalSlant, _fakenewsMixinsRetrieveNested) {
  exports['default'] = _ember['default'].Mixin.create(_fakenewsMixinsPoliticalSlant['default'], _fakenewsMixinsRetrieveNested['default'], {
    title: _emberData['default'].attr('string'),
    tags: _emberData['default'].hasMany('tag'),
    articleBases: _emberData['default'].hasMany('article-base'),
    articleSupports: _emberData['default'].hasMany('article-support'),

    tagEmotions: _ember['default'].computed('tags.@each.emotions', function () {
      return this.retrieveNested(this.get('tags'), 'emotions');
    }),

    politicalSlant: _ember['default'].computed('tags.@each.party', function () {
      return this.getPoliticalSlant(this);
    }),

    emotions: _ember['default'].computed.uniq('tagEmotions'),

    accuracy: _ember['default'].computed('articleSupports.@each.accuracy', 'articleBases.@each.accuracy', function () {
      var accuracy = this.reduceBasedOn(this.get('articleSupports'), 'accuracy') + this.reduceBasedOn(this.get('articleBases'), 'accuracy') || 0;
      return Math.min(accuracy, 20);
    }),

    risk: _ember['default'].computed('articleSupports.@each.risk', 'articleBases.@each.risk', function () {
      var risk = this.reduceBasedOn(this.get('articleSupports'), 'risk') + this.reduceBasedOn(this.get('articleBases'), 'risk') || 0;
      return Math.min(risk, 20);
    }),

    believability: _ember['default'].computed('articleSupports.@each.believability', 'articleBases.@each.believability', function () {
      var believability = this.reduceBasedOn(this.get('articleSupports'), 'believability') + this.reduceBasedOn(this.get('articleBases'), 'believability') || 0;
      return Math.min(believability, 20);
    }),

    drama: _ember['default'].computed('tags.@each.drama', 'articleSupports.@each.drama', 'articleBases.@each.drama', function () {
      var drama = this.reduceBasedOn(this.get('tags'), 'drama') + this.reduceBasedOn(this.get('articleSupports'), 'drama') + this.reduceBasedOn(this.get('articleBases'), 'drama') || 0;
      return Math.min(drama, 20);
    }),

    reduceBasedOn: function reduceBasedOn(enumerable, property) {
      return enumerable.reduce(function (acc, value) {
        return acc + value.get(property);
      }, 0);
    }

  });
});
define('fakenews/components/site-options', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    money: _ember['default'].inject.service(),

    logoOptions: _ember['default'].computed.reads('model.logoOptions'),
    site: _ember['default'].computed.alias('model.site'),
    i18n: _ember['default'].inject.service(),

    readyToBuild: _ember['default'].computed.and('isAffordable', 'isComplete', 'hasMinCredibility'),

    isAffordable: _ember['default'].computed.not('isTooExpensive'),
    isComplete: _ember['default'].computed.not('isIncomplete'),

    hasMinCredibility: _ember['default'].computed('initialCredibility', 'numberOfGameSavedSites', function () {
      var hasMinCredibility = true;
      if (this.get('numberOfGameSavedSites') === 0) {
        if (this.get('initialCredibility') < 30) {
          hasMinCredibility = false;
        }
      }
      return hasMinCredibility;
    }),

    numberOfGameSavedSites: _ember['default'].computed.alias('gameData.currentGame.savedSites.length'),

    siteName: _ember['default'].computed.alias('model.site.name'),
    domainHostingOption: _ember['default'].computed.alias('model.site.domainAndHosting'),
    themeOption: _ember['default'].computed.alias('model.site.theme'),
    logoOption: null,
    monitizationOption: _ember['default'].computed.alias('model.site.monitization'),

    isTooExpensive: _ember['default'].computed('costs', function () {
      var funds = this.get('gameData.currentGame.revenue');
      var totalCost = this.get('costs.dueNow');
      return funds < totalCost;
    }),
    isIncomplete: _ember['default'].computed('site.domainAndHosting', 'site.theme', 'logoOption', 'site.monitization', 'siteName', 'hasMinCredibility', function () {
      if (_ember['default'].isPresent(this.get('siteName')) && _ember['default'].isPresent(this.get('site.domainAndHosting')) && _ember['default'].isPresent(this.get('site.theme')) && _ember['default'].isPresent(this.get('logoOption')) && _ember['default'].isPresent(this.get('site.monitization')) && this.get('hasMinCredibility')) {
        return false;
      } else {

        return true;
      }
    }),
    initialCredibility: _ember['default'].computed('site.domainAndHosting', 'site.theme', function () {
      return this.getWithDefault('site.domainAndHosting.credibility', 0) + this.getWithDefault('site.theme.credibility', 0);
    }),
    costs: _ember['default'].computed('site.domainAndHosting', 'site.theme', function () {
      var oneTimeCost = this.getWithDefault('site.theme.oneTimeCost', 0);
      var monthlyCosts = this.getWithDefault('site.domainAndHosting.monthlyCosts', 0);
      var yearlyCosts = this.getWithDefault('site.domainAndHosting.yearlyCosts', 0);
      var dueNow = oneTimeCost + monthlyCosts + yearlyCosts;

      return {
        oneTimeCost: oneTimeCost,
        monthlyCosts: monthlyCosts,
        yearlyCosts: yearlyCosts,
        dueNow: dueNow
      };
    }),
    remaining: _ember['default'].computed('costs', 'gameData.currentGame.revenue', function () {
      return this.get('gameData.currentGame.revenue') - this.get('costs').dueNow;
    }),

    selectOptionInArray: function selectOptionInArray(array, option, variable) {
      this.get(array).forEach(function (option) {
        _ember['default'].set(option, 'selected', false);
      });

      _ember['default'].set(option, 'selected', true);
      this.set(variable, option);
    },
    actions: {
      logoOptionSelected: function logoOptionSelected(logo) {
        this.selectOptionInArray('logoOptions', logo, 'logoOption');
        $('.ui.accordion').accordion('open', 2);
      },
      domainAndHostingOptionSelected: function domainAndHostingOptionSelected() {
        $('.ui.accordion').accordion('open', 3);
      },
      themeOptionSelected: function themeOptionSelected() {
        $('.ui.accordion').accordion('open', 4);
      },
      monitizationOptionSelected: function monitizationOptionSelected() {
        $('.ui.accordion').accordion('open', 5);
      },
      generateRandomName: function generateRandomName() {
        var part1Options = this.get('model.siteNames1');
        var part2Options = this.get('model.siteNames2');

        var part1 = part1Options[Math.floor(part1Options.length * Math.random())];
        var part2 = part2Options[Math.floor(part2Options.length * Math.random())];

        var wholeName = part1 + " " + part2;

        this.set('siteName', wholeName);
      },

      createSite: function createSite() {
        if (this.get('costs.oneTimeCost') > 0) {
          this.get('money').subtractFunds(this.get('costs.oneTimeCost'), this.get('site.theme').header);
        }

        if (this.get('costs.monthlyCosts') > 0) {
          this.get('money').addRecurringExpense('monthly', this.get('costs.monthlyCosts'), this.get('i18n').t('menu.monthly_hosting_fee'), true);
        }
        if (this.get('costs.yearlyCosts') > 0) {
          this.get('money').addRecurringExpense('yearly', this.get('costs.yearlyCosts'), this.get('i18n').t('create_a_site.domain_registration_for', { "siteName": this.get('siteName') }), true);
        }
        var currentGame = this.get('gameData.currentGame');

        if (this.get('site.theme').key) {
          currentGame.get('purchasedSiteItems').addObject(this.get('site.theme').key);
        }
        if (this.get('site.domainAndHosting').key) {
          currentGame.get('purchasedSiteItems').addObject(this.get('site.domainAndHosting').key);
        }

        currentGame.set('purchasedSiteItems', currentGame.get('purchasedSiteItems').uniq());

        var site = this.get('model.site');

        site.setProperties({
          logo: this.get('logoOption.src'),
          displaysAds: true,
          game: currentGame,
          saved: true
        });

        site.save();
        currentGame.save();
        this.sendAction('siteCreated', site);
      }
    }
  });
});
define('fakenews/components/buy-profiles', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    store: _ember['default'].inject.service(),
    money: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),
    notifications: _ember['default'].inject.service('notification-messages'),
    actions: {
      buyProfile: function buyProfile(profile) {
        var _this = this;

        if (profile.get('cost') <= this.get('gameData.currentGame.revenue')) {
          profile.get('groups').then(function (groups) {
            var profileData = {
              name: profile.get('name'),
              photo: profile.get('photo'),
              politicalSlant: profile.get('politicalSlant'),
              credibility: profile.get('credibility'),
              friends: profile.get('friends'),
              groups: groups,
              copiedFrom: profile,
              game: _this.get('gameData.currentGame'),
              saved: true
            };
            var newProfile = _this.get('store').createRecord('user-profile', profileData);
            newProfile.save();
            _this.get('money').subtractFunds(profile.get('cost'), _this.get('i18n').t('plant_an_article.profile.success') + " " + profile.get('name'));
            _this.sendAction('selectProfile', newProfile);
          });
        } else {
          this.get('notifications').error(this.get('i18n').t('plant_an_article.profile.too_expensive'), {
            autoClear: true,
            clearDuration: 2400
          });
        }
      }
    }
  });
});
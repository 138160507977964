define('fakenews/models/article-support', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    text: _emberData['default'].attr('string'),
    tags: _emberData['default'].hasMany('tag'),
    matching: _emberData['default'].attr('boolean', { defaultValue: false }),

    hasTags: _ember['default'].computed('tags', function () {
      return this.get('tags.length') > 0;
    }),

    isPartyNeutral: _ember['default'].computed.equal('party', 0),
    isOrangeParty: _ember['default'].computed.equal('party', 1),
    isPurpleParty: _ember['default'].computed.equal('party', 2),

    party: _emberData['default'].attr('number', { defaultValue: 0 }),

    believability: _emberData['default'].attr('number', { defaultValue: 0 }),
    accuracy: _emberData['default'].attr('number', { defaultValue: 0 }),
    drama: _emberData['default'].attr('number', { defaultValue: 0 }),
    risk: _emberData['default'].attr('number', { defaultValue: 0 }),

    articles: _emberData['default'].hasMany('article'),
    userArticles: _emberData['default'].hasMany('user-article')
  });
});
define('fakenews/storages/user-articles', ['exports', 'ember-local-storage/local/array'], function (exports, _emberLocalStorageLocalArray) {

  var Storage = _emberLocalStorageLocalArray['default'].extend();

  // Uncomment if you would like to set initialState
  // Storage.reopenClass({
  //   initialState() {
  //     return [];
  //   }
  // });

  exports['default'] = Storage;
});
define('fakenews/mixins/political-slant', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    getPoliticalSlant: function getPoliticalSlant(objectWithTags) {
      var tags = objectWithTags.get('tags');

      var partyTags = tags.rejectBy('party', 0);

      var partyTagValues = partyTags.mapBy('party');

      var numberOfParties = partyTagValues.uniq().get('length');

      if (numberOfParties === 0) {
        return 0;
      } else if (numberOfParties === 2) {
        return 3;
      } else {
        return partyTags.objectAt(0).get('party');
      }
    }
  });
});
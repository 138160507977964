define('fakenews/components/create-profile', ['exports', 'ember', 'fakenews/mixins/has-time-passing-modal'], function (exports, _ember, _fakenewsMixinsHasTimePassingModal) {
  exports['default'] = _ember['default'].Component.extend(_fakenewsMixinsHasTimePassingModal['default'], {
    store: _ember['default'].inject.service(),
    heartbeat: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    selectedCustomization: null,
    newProfile: null,
    isReadyToSubmit: _ember['default'].computed('newProfile.name', 'newProfile.photo', 'newProfile.politicalSlant', 'selectedCustomization', function () {
      return _ember['default'].isPresent(this.get('newProfile.name')) && _ember['default'].isPresent(this.get('newProfile.photo')) && _ember['default'].isPresent(this.get('newProfile.politicalSlant')) && _ember['default'].isPresent(this.get('selectedCustomization'));
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this.startNewProfile();
    },
    startNewProfile: function startNewProfile() {
      var profileData = {
        created: true,
        hacked: true
      };
      this.set('newProfile', this.get('store').createRecord('user-profile', profileData));
    },
    profileImages: ['person_1.png', 'person_2.png', 'person_3.png', 'person_4.png', 'person_5.png', 'person_6.png', 'person_7.png', 'person_8.png', 'person_9.png', 'person_10.png', 'person_11.png', 'person_12.png', 'person_13.png', 'person_14.png', 'person_15.png'],
    customizationOptions: [{
      text: "create_profile.customization_options.low",
      hours: 1,
      credibility: 2
    }, {
      text: "create_profile.customization_options.medium",
      hours: 3,
      credibility: 8
    }, {
      text: "create_profile.customization_options.high",
      hours: 5,
      credibility: 12
    }],
    finishCreateProfile: function finishCreateProfile(self) {
      self.closeTimePassingModal();
      var newProfile = self.get('newProfile');
      newProfile.credibility = self.get('selectedCustomization').credibility;
      newProfile.friends = Math.floor(self.get('selectedCustomization').hours * Math.random() * 2);

      newProfile.setProperties({
        saved: true,
        game: self.get('gameData.currentGame')
      });

      newProfile.save();

      self.sendAction('selectProfile', newProfile);

      self.set('newProfile', null);
      self.startNewProfile();
    },
    actions: {
      startCreateProfile: function startCreateProfile() {
        this.get('heartbeat').startSpeedHeartbeat(this.get('selectedCustomization').hours, this.finishCreateProfile, this);
        this.openTimePassingModal(this.get('i18n').t('create_profile.customizing_profile'), "");
      }
    }
  });
});
define("fakenews/templates/components/winning-modal", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 0
          }
        },
        "moduleName": "fakenews/templates/components/winning-modal.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "header");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "image content");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("img");
        dom.setAttribute(el2, "class", "image");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "description");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "ui blue fluid button");
        dom.setAttribute(el4, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "ui info circle icon");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "ui blue fluid button");
        dom.setAttribute(el4, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "ui undo icon");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createElement("button");
        dom.setAttribute(el4, "class", "ui blue fluid button");
        dom.setAttribute(el4, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "ui sign out icon");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(element2, [3, 0]);
        var element4 = dom.childAt(element2, [7, 0]);
        var element5 = dom.childAt(element2, [9, 0]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createAttrMorph(element1, 'src');
        morphs[2] = dom.createMorphAt(element2, 1, 1);
        morphs[3] = dom.createElementMorph(element3);
        morphs[4] = dom.createMorphAt(element3, 2, 2);
        morphs[5] = dom.createMorphAt(dom.childAt(element2, [5]), 0, 0);
        morphs[6] = dom.createElementMorph(element4);
        morphs[7] = dom.createMorphAt(element4, 2, 2);
        morphs[8] = dom.createElementMorph(element5);
        morphs[9] = dom.createMorphAt(element5, 2, 2);
        return morphs;
      },
      statements: [["inline", "t", ["winning.header"], [], ["loc", [null, [2, 2], [2, 24]]], 0, 0], ["attribute", "src", ["concat", ["../../../images/", ["get", "gameData.currentGame.gameGoal.imgSrc", ["loc", [null, [5, 44], [5, 80]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["winning.description"], ["gameGoal", ["subexpr", "@mut", [["get", "gameData.currentGame.gameGoal.longDescription", ["loc", [null, [7, 39], [7, 84]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 4], [7, 86]]], 0, 0], ["element", "action", ["learnMore"], [], ["loc", [null, [8, 44], [8, 66]]], 0, 0], ["inline", "t", ["menu.go_to_about"], [], ["loc", [null, [8, 103], [8, 127]]], 0, 0], ["inline", "t", ["winning.can_return_prompt"], [], ["loc", [null, [9, 7], [9, 40]]], 0, 0], ["element", "action", ["closeModal"], [], ["loc", [null, [10, 44], [10, 67]]], 0, 0], ["inline", "t", ["menu.back_to_current_game"], [], ["loc", [null, [10, 97], [10, 130]]], 0, 0], ["element", "action", ["backToIndex"], [], ["loc", [null, [11, 44], [11, 68]]], 0, 0], ["inline", "t", ["menu.go_to_main_menu"], [], ["loc", [null, [11, 102], [11, 130]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});
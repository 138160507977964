define('fakenews/components/standard-filters-display', ['exports', 'ember', 'ember-cli-pagination/computed/paged-array', 'ember-cli-filter-by-query/util/filter'], function (exports, _ember, _emberCliPaginationComputedPagedArray, _emberCliFilterByQueryUtilFilter) {
  exports['default'] = _ember['default'].Component.extend({

    page: 1,
    perPage: 2,
    totalPagesBinding: "pagedContent.totalPages",

    hasMultiplePages: _ember['default'].computed.gt('pagedContent.totalPages', 1),

    pagedContent: (0, _emberCliPaginationComputedPagedArray['default'])('contentToUse', { pageBinding: "page", perPageBinding: "perPage" }),

    contentToUse: _ember['default'].computed('showSort', 'filteredContent', 'sortedContent', function () {
      if (this.get('showSort')) {
        return this.get('sortedContent');
      } else {
        return this.get('filteredContent');
      }
    }),

    noneToDisplay: _ember['default'].computed.lt('filteredContent.length', 1),

    sortedContent: _ember['default'].computed.sort('filteredContent', 'sortDefinition'),
    sortBy: 'lastTouchDate',
    reverseSort: false,
    sortDefinition: _ember['default'].computed('sortBy', 'reverseSort', function () {
      var sortOrder = this.get('reverseSort') ? 'asc' : 'desc';
      return [this.get('sortBy') + ':' + sortOrder];
    }),

    filteredContent: _ember['default'].computed('items.@each.politicalSlant', 'selectedParty', 'topicInput', function () {
      var matchingItems = this.get('items');
      this.set('page', 1);

      if (this.get('selectedParty') !== "any") {
        matchingItems = matchingItems.filterBy('politicalSlant', parseInt(this.get('selectedParty')));
      }
      if (this.get('topicInput') !== '') {
        matchingItems = matchingItems.filter(function (item) {
          var results = (0, _emberCliFilterByQueryUtilFilter['default'])(item.get('tags'), ['name', 'subject', 'topic'], this.get('topicInput'));
          return results.get('length') > 0;
        }, this);
        //matchingItems = ;
      }

      return matchingItems;
    }),

    selectedParty: "any",

    topicInput: ''
  });
});
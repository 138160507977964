define('fakenews/models/user-plant', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    article: _emberData['default'].belongsTo('user-article'),
    plantedBy: _emberData['default'].belongsTo('user-profile'),
    plantedTo: _emberData['default'].belongsTo('group'),
    site: _emberData['default'].belongsTo('site'),
    hoursSincePlanted: _emberData['default'].attr('number', { defaultValue: 0 }),
    createdAt: _emberData['default'].attr('date'),

    feedback: _emberData['default'].attr(''),
    interestLevel: _emberData['default'].attr(''),

    isPoliticalMatch: _emberData['default'].attr('boolean', { defaultValue: false }),
    isPoliticalMismatch: _emberData['default'].attr('boolean', { defaultValue: false }),
    isNeutralGroup: _emberData['default'].attr('boolean', { defaultValue: false }),
    isNeutralArticle: _emberData['default'].attr('boolean', { defaultValue: false }),

    success: _ember['default'].computed.gt('shares', 10),

    active: _emberData['default'].attr('boolean', { defaultValue: false }),

    views: _emberData['default'].attr('number', { defaultValue: 0 }),
    likes: _emberData['default'].attr('number', { defaultValue: 0 }),
    shares: _emberData['default'].attr('number', { defaultValue: 0 }),
    revenue: _emberData['default'].attr('number', { defaultValue: 0 }),

    hasHighBelievability: _ember['default'].computed.gt('article.believability', 15),
    hasHighDrama: _ember['default'].computed.gt('article.drama', 15),
    hasModerateBelievability: _ember['default'].computed.gt('article.believability', 11),
    hasModerateDrama: _ember['default'].computed.gt('article.drama', 11),
    hasMildBelievability: _ember['default'].computed.gt('article.believability', 6),
    hasMildDrama: _ember['default'].computed.gt('article.drama', 6),

    hasLowSiteCredibility: _ember['default'].computed.lt('site.credibility', 30),

    hasProfileHighCredibility: _ember['default'].computed.gt('plantedBy.credibility', 10),

    trendingTopicMatches: _emberData['default'].hasMany('tag', { inverse: 'userPlantsTrending' }),
    wasTrendingTopic: _ember['default'].computed.gt('trendingTopicMatches.length', 0),

    groupTopicMatches: _emberData['default'].hasMany('tag', { inverse: 'userPlantsGroupMatches' }),
    isGroupTopicMatch: _ember['default'].computed.gt('groupTopicMatches.length', 0),

    emotions: _emberData['default'].hasMany('emotion'),
    hasTriggeredEmotions: _ember['default'].computed.gt('emotions.length', 0),

    // If it matches what people already believe
    likeFactor: _ember['default'].computed('interestLevel', function () {
      var interest = 0;
      switch (this.get('interestLevel')) {
        case 'none':
          interest = 0;
          break;
        case 'mild':
          interest = 2;
          break;
        case 'moderate':
          interest = this.get('groupTopicMatches.length') + this.get('article.believability');
          break;
        case 'good':
          interest = this.get('article.drama') / 5 + this.get('groupTopicMatches.length') * 4 + this.get('trendingTopicMatches.length');
          break;
        case 'viral':
          interest = this.get('article.drama') + this.get('groupTopicMatches.length') * 4 + this.get('trendingTopicMatches.length') * 8;
          break;
      }

      return interest;
    }),

    shareFactor: _ember['default'].computed('likeFactor', function () {
      return this.get('likeFactor');
    }),

    viewFactor: _ember['default'].computed('likeFactor', function () {
      var interest = Math.floor(this.get('likeFactor') * Math.random() * 5);
      if (this.get('interestLevel') === 'hostile') {
        interest += 8;
      }
      return interest;
    }),

    numberOfComments: _ember['default'].computed('interestLevel', function () {
      var numberOfComments = 0;
      var interestLevel = this.get('interestLevel');
      if (interestLevel === 'viral' || interestLevel === 'hostile') {
        numberOfComments = 8;
      } else if (interestLevel === 'good') {
        numberOfComments = 7;
      } else if (interestLevel === 'moderate') {
        numberOfComments = 5;
      } else if (interestLevel === 'mild') {
        numberOfComments = 3;
      }

      return numberOfComments;
    }),

    comments: _emberData['default'].hasMany('comment'),
    commentBubbles: _emberData['default'].attr('')

  });
});
define('fakenews/app', ['exports', 'ember', 'fakenews/resolver', 'ember-load-initializers', 'fakenews/config/environment'], function (exports, _ember, _fakenewsResolver, _emberLoadInitializers, _fakenewsConfigEnvironment) {

  var App = undefined;

  _ember['default'].deprecate = function () {};

  _ember['default'].MODEL_FACTORY_INJECTIONS = true;

  App = _ember['default'].Application.extend({
    modulePrefix: _fakenewsConfigEnvironment['default'].modulePrefix,
    podModulePrefix: _fakenewsConfigEnvironment['default'].podModulePrefix,
    Resolver: _fakenewsResolver['default']
  });

  (0, _emberLoadInitializers['default'])(App, _fakenewsConfigEnvironment['default'].modulePrefix);

  exports['default'] = App;
});
define('fakenews/routes/play/site/write-article', ['exports', 'ember', 'rsvp'], function (exports, _ember, _rsvp) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return _rsvp['default'].hash({
        bases: this.get('store').findAll('article-base'),
        supports: this.get('store').findAll('article-support'),
        emotions: this.get('store').findAll('emotion'),

        comments: this.get('store').findAll('comment'),
        site: this.modelFor('play.site')
      });
    },
    afterModel: function afterModel() {
      this.get('gameData').resetPauseAndModal();
    }
  });
});
define('fakenews/controllers/play/initial', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    game: _ember['default'].computed.alias('gameData.currentGame'),

    allCompleted: _ember['default'].computed.and('game.userName', 'game.gameGoal.longDescription', 'game.guide.imgSrc'),

    selectOptionInArray: function selectOptionInArray(optionArray, option) {
      optionArray.forEach(function (option) {
        _ember['default'].set(option, 'selected', false);
      });

      _ember['default'].set(option, 'selected', true);
    },

    actions: {
      submitGoal: function submitGoal(goal) {
        this.selectOptionInArray(this.get('model.goals'), goal);
        this.get('game').set('gameGoal', goal);
        $('.ui.accordion').accordion('open', 3);
      },
      submitGuide: function submitGuide(guide) {
        this.selectOptionInArray(this.get('model.guides'), guide);
        this.get('game').set('guide', guide);
        $('.ui.accordion').accordion('open', 1);
      },
      submitInitial: function submitInitial() {
        this.set('game.writeArticlesEnabled', false);
        this.get('game').save();

        this.transitionToRoute('play.office');
      }
    }
  });
});
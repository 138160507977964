define('fakenews/components/opening-options', ['exports', 'ember', 'fakenews/mixins/pause-page'], function (exports, _ember, _fakenewsMixinsPausePage) {
  exports['default'] = _ember['default'].Component.extend(_fakenewsMixinsPausePage['default'], {
    routing: _ember['default'].inject.service('-routing'),

    displayedGames: _ember['default'].computed('games.length', function () {
      var games = this.get('games').rejectBy('userName', "");
      games = games.sortBy('lastPlayed');
      games = games.reverse();
      return games;
    }),

    actions: {
      selectGame: function selectGame(game) {
        this.set('gameData.currentGame', game);
        this.get('routing').transitionTo('play.sites');
      },
      startNewGame: function startNewGame() {
        this.get('gameData').createNewGame();
      },
      deleteGame: function deleteGame(game) {
        game.destroyRecord();
      },
      closePopup: function closePopup() {
        $('.deletePopUp').popup('hide');
      }
    }

  });
});
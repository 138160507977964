define('fakenews/services/game-data', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    planter: _ember['default'].inject.service(),
    heartbeat: _ember['default'].inject.service(),
    trendingTags: _ember['default'].inject.service('trending-tags'),
    goals: _ember['default'].inject.service(),
    notifications: _ember['default'].inject.service('notification-messages'),
    routing: _ember['default'].inject.service('-routing'),
    i18n: _ember['default'].inject.service(),

    articleToMove: null,

    currentSite: null,

    timePassingModalText: "",
    timePassingModalTitle: "",

    gamePaused: false,

    currentGame: null,

    hasLastArticle: _ember['default'].computed.notEmpty('lastArticle'),
    lastArticle: null,

    currentArticle: null,
    currentProfile: null,
    currentGroup: null,

    store: _ember['default'].inject.service(),

    init: function init() {
      this._super.apply(this, arguments);

      var self = this;
      this.get('store').findAll('game').then(function (games) {
        games = games.sortBy('lastPlayed');
        var game = games.get('lastObject');
        self.loadGame(game, self);
      });
    },

    createNewGame: function createNewGame() {
      var self = this;
      this.get('store').findAll('tag').then(function (tags) {
        var initialTrendingTags = self.get('trendingTags').fillTrendingTags([], tags);
        _ember['default'].$.getJSON('../../data/smallGoals.json').then(function (data) {
          var goals = data.uniq();
          var newGame = self.get('store').createRecord('game', {
            trendingTags: initialTrendingTags,
            allGoals: goals,
            expenseLog: [],
            purchasedSiteItems: []
          });
          _ember['default'].set(self, 'currentGame', newGame);
          newGame.save();
          self.get("routing").transitionTo("play.initial");
        });
      });
    },

    loadGame: function loadGame(game, self) {
      _ember['default'].set(self, 'currentGame', game);
      self.get('heartbeat').startHeartbeat();
    },

    readyToPlant: _ember['default'].computed('currentArticle', 'currentProfile', 'currentGroup', function () {
      return _ember['default'].isPresent(this.get('currentArticle')) && _ember['default'].isPresent(this.get('currentProfile')) && _ember['default'].isPresent(this.get('currentGroup'));
    }),

    updatePlantProperty: function updatePlantProperty(item, property, change) {
      if (change > 0) {
        item.incrementProperty(property, change);

        var site = item.get('site');
        site.incrementProperty(property, change);
        site.get('content').save();
        this.get('currentGame').incrementProperty(property, change);
      }
    },

    resetSelectionsToNull: function resetSelectionsToNull() {
      this.setProperties({
        currentArticle: null,
        currentProfile: null,
        currentGroup: null
      });
    },

    selectArticle: function selectArticle(article) {
      this.set('currentArticle', article);
      this.set('lastArticle', article);
    },
    selectProfile: function selectProfile(profile) {
      this.set('currentProfile', profile);
    },
    selectGroup: function selectGroup(group) {
      this.set('currentGroup', group);
    },

    getPoliticalSlant: function getPoliticalSlant(objectWithTags) {
      var tags = objectWithTags.get('tags');

      var partyTags = tags.rejectBy('party', 0);

      var partyTagValues = partyTags.mapBy('party');

      var numberOfParties = partyTagValues.uniq().get('length');

      if (numberOfParties === 0) {
        return 0;
      } else if (numberOfParties === 2) {
        return 3;
      } else {
        return partyTags.objectAt(0).get('party');
      }
    },

    modalText: "",
    modalShown: false,
    modalTexts: [],
    openModal: function openModal(modalText) {
      this.get('modalTexts').push(modalText);
      if (!this.get('modalShown')) {
        this.launchModal();
      }
    },

    resetPauseAndModal: function resetPauseAndModal() {
      this.set('gamePaused', false);
      this.set('modalShown', false);
    },

    launchModal: function launchModal() {
      var _this = this;

      if (this.get('modalTexts').length > 0) {
        (function () {
          _this.set('modalShown', true);

          var newText = _this.get('modalTexts').shift();
          _this.set('modalText', newText);

          _this.set('gamePaused', true);
          var self = _this;
          $('.ui.guide.modal').modal({
            onHidden: function onHidden() {
              self.closeModal();
            }
          }).modal('setting', 'closable', false).modal('show');
        })();
      }
    },

    closeModal: function closeModal() {
      this.set('gamePaused', false);
      this.set('modalShown', false);
      this.launchModal();
    },

    debunkArticle: function debunkArticle(article) {

      var debunkMessage = this.get('i18n').t('special_events.debunked.part_one', { articleTitle: article.get('title') });

      if (!this.get('currentGame.hasDisplayedFirstArticleDebunked')) {
        debunkMessage = debunkMessage + this.get('i18n').t('special_events.debunked.part_two_first', { siteName: article.get('site.name') });
        this.set('currentGame.hasDisplayedFirstArticleDebunked', true);
        this.get('currentGame').save();
      } else {
        debunkMessage = debunkMessage + this.get('i18n').t('special_events.debunked.part_two_subsequent', { siteName: article.get('site.name') });
      }

      this.openModal(debunkMessage);
      article.set('debunked', true);
      article.save();
    },
    famousShare: function famousShare(article) {

      var person = this.get('i18n').t('special_events.famous_share.a_celebrity');
      var random = Math.random();

      if (random > 0.7) {
        if (article.get('politicalSlant') === 1) {
          person = this.get('i18n').t('special_events.orange_party_leader');
        } else {
          person = "this.get('i18n').t('special_events.famous_share.purple_party_leader')";
        }
      }

      var message = this.get('i18n').t('special_events.famous_share.part_one', { person: person, articleTitle: article.get('title') });

      if (!this.get('currentGame.hasDisplayedFamousShare')) {
        message = message + this.get('i18n').t('special_events.famous_share.part_two_first');
        this.set('currentGame.hasDisplayedFamousShare', true);
        this.get('currentGame').save();
      }

      this.openModal(message);
      article.set('famousShare', true);
      article.set('famousShareBonus', Math.floor(random * 100));
      article.save();
    },
    textOnlySpecialEvent: function textOnlySpecialEvent(article, type) {
      var messagePath = 'special_events.' + type;
      var message = this.get('i18n').t(messagePath, { articleTitle: article.get('title') });
      this.openModal(message);
    }

  });
});
define('fakenews/services/goals', ['exports', 'ember', 'fakenews/mixins/retrieve-nested', 'ember-concurrency'], function (exports, _ember, _fakenewsMixinsRetrieveNested, _emberConcurrency) {
  exports['default'] = _ember['default'].Service.extend(_fakenewsMixinsRetrieveNested['default'], {
    gameData: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),
    moment: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    activeGoal: null,

    activeGoals: _ember['default'].computed('nextGoal.text', function () {
      var activeGoals = [];
      var nextGoal = this.get('nextGoal');
      activeGoals.addObject(nextGoal);
      return activeGoals;
    }),
    nextGoal: _ember['default'].computed('gameData.currentGame.allGoals.@each.completed', function () {
      var nextGoal = this.get('gameData.currentGame.allGoals').find(function (goal) {
        return goal.completed === false;
      }, this);
      return nextGoal;
    }),

    hasGamePropertyOf: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(goal) {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            return context$1$0.abrupt('return', this.get('gameData.currentGame').get(goal.property) >= goal.value);

          case 1:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    hasMinValueOf: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(goal) {
      var itemArray, matchingItem, maxItem;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            itemArray = this.get('gameData.currentGame').get(goal.lookIn).filter(function (item) {
              return this.okayDate(item);
            }, this);
            matchingItem = undefined;

            if (!(itemArray.get('length') > 0)) {
              context$1$0.next = 6;
              break;
            }

            maxItem = itemArray.sortBy(goal.property).get('lastObject');

            if (maxItem.get(goal.property) >= goal.value) {
              matchingItem = maxItem;
            }
            return context$1$0.abrupt('return', matchingItem);

          case 6:
            return context$1$0.abrupt('return', matchingItem);

          case 7:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    okayDate: function okayDate(item) {
      var goal = this.get('nextGoal');
      if (_ember['default'].isPresent(goal.compareDateTo)) {
        if (item.get(goal.compareDateTo).getTime() > this.get('gameData.currentGame.dateStartedGoal').getTime()) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },

    hasMinMatchingObjects: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(goal) {
      var itemArray, matchingItem, matchesArray;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            itemArray = this.get('gameData.currentGame').get(goal.lookIn).filter(function (item) {
              return this.okayDate(item);
            }, this);
            matchingItem = undefined;

            if (itemArray.get('length') > 0) {
              matchesArray = itemArray.filterBy(goal.property);

              if (matchesArray.get('length') >= goal.value) {
                matchingItem = matchesArray.get('lastObject');
              }
            }
            return context$1$0.abrupt('return', matchingItem);

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    matchesNestedProperty: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(goal) {
      var itemArray, matchingItem, nestedItems, matching;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            itemArray = this.get('gameData.currentGame').get(goal.lookIn).filter(function (item) {
              return this.okayDate(item);
            }, this);
            matchingItem = undefined;

            if (itemArray.get('length') > 0) {
              nestedItems = this.retrieveNested(itemArray, goal.nestedLookIn);
              matching = nestedItems.filterBy(goal.property, goal.value);

              matchingItem = matching.get('lastObject');
            }

            return context$1$0.abrupt('return', matchingItem);

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    matchesProperty: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(goal) {
      var itemArray, matchingItem, matching;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            itemArray = this.get('gameData.currentGame').get(goal.lookIn).filter(function (item) {
              return this.okayDate(item);
            }, this);
            matchingItem = undefined;

            if (itemArray.get('length') > 0) {
              matching = itemArray.filterBy(goal.property, goal.value);

              matchingItem = matching.get('lastObject');
            }

            return context$1$0.abrupt('return', matchingItem);

          case 4:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    timesChecked: 0,
    checkGoals: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      var currentDate, activeGoals, i, x, goal, completed;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            if (this.get('gameData.currentGame.revenue') >= this.get('gameData.currentGame.gameGoal.cost') && !this.get('gameData.currentGame.hasWon')) {
              this.openWinningModal();
              this.set('gameData.currentGame.hasWon', true);

              currentDate = this.get('gameData.currentGame.date').toString();

              ga('send', 'event', 'Final Goal Completed', this.get('gameData.currentGame.gameGoal.name'), currentDate);
            }

            // Check other goals
            activeGoals = this.get('gameData.currentGame.activeGoals');
            i = 0, x = activeGoals.get('length');

          case 3:
            if (!(i < x)) {
              context$1$0.next = 13;
              break;
            }

            goal = activeGoals[i];

            if (goal.completed) {
              context$1$0.next = 10;
              break;
            }

            context$1$0.next = 8;
            return this.get(goal.goalType).perform(goal);

          case 8:
            completed = context$1$0.sent;

            if (completed) {
              this.completeGoal(goal);
              this.set('timesChecked', 0);
            } else {
              if (_ember['default'].isPresent(goal.hint) && _ember['default'].isBlank(goal.hintShown)) {
                this.incrementProperty('timesChecked');
                if (!this.get('gameData.currentGame.hasDisplayedHintMessage')) {
                  if (this.get('timesChecked') === 50) {
                    this.promptHint(goal);
                  }
                } else if (this.get('timesChecked') === 200) {
                  this.promptHint(goal);
                }
              }
            }

          case 10:
            i++;
            context$1$0.next = 3;
            break;

          case 13:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    promptHint: function promptHint(goal) {
      var modalText = this.get('i18n').t('goals_display.hint_prompt', { "goalText": goal.text });
      this.get('gameData').openModal(modalText);
      _ember['default'].set(goal, 'hintShown', true);

      var currentDate = this.get('gameData.currentGame.date').toString();
      ga('send', 'event', 'Hints Shown', goal.text, currentDate);
    },

    openWinningModal: function openWinningModal() {
      this.set('gameData.gamePaused', true);
      var self = this;
      $('.ui.winning.modal').modal({
        onHidden: function onHidden() {
          self.set('gameData.gamePaused', false);
        }
      }).modal('show');
    },

    modalText: "",
    completeGoal: function completeGoal(goal) {
      _ember['default'].set(goal, 'completed', true);

      var nextGoal = this.get('nextGoal');

      var modalText = goal.conclusion;

      if (nextGoal !== undefined) {
        if (_ember['default'].isPresent(nextGoal.triggerEventOnStart)) {
          this.get(nextGoal.triggerEventOnStart).perform();
        }

        modalText += " " + this.get('i18n').t('goals_display.next_goal_introduction') + "<ul><li>" + nextGoal.text + "</li></ul>" + nextGoal.introduction + "<br><br>" + this.get('i18n').t('goals_display.next_goal_introduction_2');
      } else {
        modalText += "<p>" + this.get('i18n').t('goals_display.final_goal_complete') + "</p>";
        this.set('gameData.currentGame.goalsCompleted', true);
      }
      this.set('modalText', modalText);
      this.openModal();

      var currentDate = this.get('gameData.currentGame.date').toString();
      ga('send', 'event', 'Intermediate Goals Completed', goal.text, currentDate);

      if (_ember['default'].isPresent(nextGoal.compareDateTo)) {
        this.set('gameData.currentGame.dateStartedGoal', this.get('gameData.currentGame.date'));
      }
    },

    showTrendingTopics: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.set('gameData.currentGame.trendingTopicsEnabled', true);

          case 1:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    showWriteArticle: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.set('gameData.currentGame.writeArticlesEnabled', true);

          case 1:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    famousShareOn: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.set('gameData.currentGame.famousShareOn', true);

          case 1:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    introduceFirstGoal: function introduceFirstGoal() {
      var goal = this.get('nextGoal');
      var modalText = goal.introduction;

      this.set('modalText', modalText);
      this.openModal();
    },

    openModal: function openModal() {
      this.get('gameData').openModal(this.get('modalText'));
    },

    reviewGoal: function reviewGoal(goal) {
      var modalText = "<ul><li>" + goal.text + "</li></ul>" + goal.introduction;
      if (goal.hint) {
        modalText = modalText + "<h5>" + this.get('i18n').t('labels.try_this') + "</h5>" + goal.hint;
      }

      this.set('modalText', modalText);
      this.set('gameData.currentGame.hasDisplayedHintMessage', true);
      this.openModal();
    }

  });
});
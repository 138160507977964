define('fakenews/models/article-base', ['exports', 'ember-data', 'fakenews/mixins/political-slant', 'ember'], function (exports, _emberData, _fakenewsMixinsPoliticalSlant, _ember) {
  exports['default'] = _emberData['default'].Model.extend(_fakenewsMixinsPoliticalSlant['default'], {
    description: _emberData['default'].attr('string'),
    suggestion: _emberData['default'].attr('string'),
    category: _emberData['default'].attr('string'),

    politicalSlant: _ember['default'].computed('tags.@each.party', function () {
      return this.getPoliticalSlant(this);
    }),

    believability: _emberData['default'].attr('number', { defaultValue: 0 }),
    accuracy: _emberData['default'].attr('number', { defaultValue: 0 }),
    drama: _emberData['default'].attr('number', { defaultValue: 0 }),
    risk: _emberData['default'].attr('number', { defaultValue: 0 }),

    articles: _emberData['default'].hasMany('article'),
    tags: _emberData['default'].hasMany('tag', { inverse: 'articleBases' }),
    hiddenTags: _emberData['default'].hasMany('tag', { inverse: 'hiddenArticleBases' }),

    userArticles: _emberData['default'].hasMany('user-article')
  });
});
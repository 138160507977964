define("fakenews/locales/en/translations", ["exports"], function (exports) {
  exports["default"] = {
    "meta": {
      "title": "Fake It To Make It",
      "description": "Create your own (fake) news empire by playing Fake It To Make It, a simulation-style, social-impact game about how fake news is created and distributed for a profit."
    },
    "home_page": {
      "paragraph_1_1": "Welcome to Fake It To Make It, a social-impact game about fake news. You can read more about this game",
      "paragraph_1_2": "on this page",
      "paragraph_1_3": ". However, I suggest that you play first!",
      "paragraph_2": "If you need to stop in the middle of your game, it should be okay, as long as you have local storage enabled. (You probably do.) Just come back to this main menu, where you will find your automatically saved games.",
      "paragraph_3": "While this game does work in mobile browsers, you will probably be happier playing on something wider, like a computer or a tablet, instead of a phone.",
      "your_existing_games": "Your existing games:",
      "last_played": "Last played",
      "total_funds": "Total funds:",
      "delete_confirm": "Are you sure that you want to delete this game? This cannot be undone!"
    },
    "new_game": {
      "header": "Create A New Game",
      "music_off": "If you would rather play your own tunes (or exist in silence), you can turn off the game music using the circular audio button on the far right side of the menu.",
      "guide_prompt": "Who do you want to guide you?",
      "name_prompt": "What is your first name?",
      "name_placeholder": "First name",
      "purchase_prompt": "What do you want to purchase?",
      "purchase_hint": "Hint: it's definitely easier to meet a smaller financial goal. You can always try for the apartment or the car later!",
      "get_started": {
        "prompt": "Let's Get Started!",
        "instructions": "Check your selections on the right. If they look good, click the Let's Go button to get started!",
        "missing": {
          "overall": "Before we can get started, you need to:",
          "guide": "Select a guide",
          "name": "Enter your first name",
          "goal": "Select a goal (something to purchase)"
        }
      },
      "name_summary": "Hey there {{userName}}!",
      "goal_summary": "Let's start earning some money so that you can {{goalLongDescription}}!"
    },
    "create_a_site": {
      "header": "Create A Site",
      "site_name": "Site Name",
      "generate_random": "Generate Random",
      "name_placeholder": "Enter a site name...",
      "logo": "Logo",
      "domain_and_hosting": "Domain and hosting",
      "monthly_hosting_fee": "Monthly Hosting Fee",
      "domain_registration_for": "Domain Registration For {{siteName}}",
      "domain_info": "Even though some fake news sites using a free hosting option have done well, registering a domain definitely adds credibility and isn't that expensive.",
      "theme": "Theme",
      "theme_info": "Using the free software LetterPress, you can set up your site quickly and easily! You can invest in a theme to make your site appear more credible.",
      "monetization": "Monetization",
      "review": {
        "instructions": "Review and Submit",
        "ready_to_submit": "Review the information on the right. If everything looks good, click the Create Site button.",
        "too_expensive": "Setting up this site requires more funds than you currently have! Go back to previous steps and change your selections to reduce expenses.",
        "missing": {
          "overall": "Before you can continue, you must:",
          "name": "Enter a site name",
          "logo": "Select a logo",
          "domain_hosting": "Select an option for a domain and hosting",
          "theme": "Select an option for a theme",
          "monetization": "Select a monetization option",
          "credibility": "Select options that give your site an initial credibility rating of at least 30. You may need to change your domain, hosting, and theme selections"
        }
      },
      "action_button": "Create This Site",
      "summary": {
        "one_time_cost": "One-Time Cost",
        "monthly_costs": "Monthly Costs",
        "yearly_costs": "Yearly Costs",
        "total": "Total",
        "due_now": "Due Now:",
        "your_funds": "Your Funds:",
        "remaining": "Remaining:"
      }
    },
    "sites": {
      "header": "{{userName}}'s News Empire",
      "prompt": "Select a site below",
      "create_site": "Create Site"
    },
    "plant_an_article": {
      "header": "Plant An Article",
      "action_button": "Plant Article",
      "article": {
        "instructions": "Select Article from {{siteName}}",
        "none": "This site doesn't have any articles! Before you can plant an article, you must copy some. Use the menu item above!"
      },
      "profile": {
        "instructions": "Select SocialMedia Profile",
        "select_profile": "Select From Your Profiles",
        "buy_profiles": "Buy Profiles",
        "select_to_buy_for": "Select to buy for ${{amount}}",
        "create_a_new_profile": "Create A New Profile",
        "none": "To start the spread of your article, you need to create or buy a fake SocialMedia profile. Use the menu items above.",
        "success": "Purchased SocialMedia profile",
        "too_expensive": "You cannot afford this profile"
      },
      "group": {
        "instructions": "Select SocialMedia Group",
        "select_group": "Select From {{profileName}}'s Groups",
        "join_groups": "Join Groups",
        "none": "This profile is not a member of any groups! Use the Join Groups tab above to find a group to join. You may want to request membership to more than one group.",
        "no_profile_selected": "You need to select a profile before you can select a group.",
        "pending_approval": "{{profile}} has requested to join the group {{group}}. You can request to join other groups while you wait.",
        "added": "{{profile}} has been added to the group {{group}}",
        "rejected": "{{profile}} has been rejected from the group {{group}}"
      },
      "review": {
        "instructions": "Review and Submit",
        "ready_to_submit": "Ready to plant your article? Click the Plant Article button on the right! Or, you can make changes to your combination by revisitng a step above.",
        "missing": {
          "overall": "Before you can continue, you must:",
          "article": "Select an article",
          "profile": "Select a SocialMedia Profile",
          "group": "Select a SocialMedia Group"
        }
      },
      "feedback": {
        "interest_level": {
          "none": "Snooze! People in this group didn't really seem to care about this article. Try something else.",
          "mild": "A few people seem interested in this article.",
          "moderate": "It looks like this article is generating some interest.",
          "good": "Nice work! People seem to care about this article and are spreading it as a result.",
          "viral": "Whoa! This article has high believability, high drama, and focuses on a trending topic that is politically aligned with this group. Perfect combination! Look at it go!",
          "hostile": "Well, you definitely made some people hostile by posting an article that was slanted towards the other political party."
        }
      }
    },
    "write_an_article": {
      "header": "Write An Article",
      "base_instructions": "Select an article base",
      "support_instructions": "Select up to four article supports",
      "support_tabs_info": "Select between the tabs below to view different types of supports.",
      "title_instructions": "Write a title",
      "submit_title": "Submit Title",
      "review": {
        "instructions": "Review",
        "ready_to_submit": "Review your article on the right. Is it believable enough? Does it have enough drama? When you're happy with the article, click the Write Article button to write it and add it to your site.",
        "missing": {
          "overall": "Before you can write an article, you need:",
          "base": "An article base",
          "title": "An article title"
        }
      },
      "action_button": "Write Article",
      "summary": {
        "your_selections": "Your Selections",
        "base": "Base",
        "supports": "Supports",
        "article_info": "This article will require {{timeRequired}} hours to write and will have the following attributes:",
        "topics": "Topics",
        "targeted_emotions": "Targeted Emotions"
      }
    },
    "special_events": {
      "debunked": {
        "part_one": "<p>The article <strong>{{articleTitle}}</strong> just got debunked on a popular fact-checking site.</p>",
        "part_two_first": "<p>You might get some extra views from people who see the article on the fact-checking site, but they are unlikely to spread it. Also, now that an article from this site has been debunked, the credibility for <strong>{{siteName}}</strong> has gone down a bit. You can see more information about a site's credibility on its dashboard.</p>",
        "part_two_subsequent": "<p>The site credibility for <strong>{{siteName}}</strong> has gone down.</p>"
      },
      "famous_share": {
        "a_celebrity": "A celebrity",
        "orange_party_leader": "Senate Leader Mango",
        "purple_party_leader": "President Aubergine",
        "part_one": "<p>{{person}} just shared a link to your article, <strong>{{articleTitle}}</strong>, on SocialMedia. Hooray!</p>",
        "part_two_first": "<p>Politicians and celebrities are not immune to fake news. This is great for you! When other people see someone they trust spreading fake news, they are more likely to believe that it’s real.</p>"
      },
      "hate_crimes": "<p>Hate crimes against the group of people that you targeted in the popular article <strong>{{articleTitle}}</strong> have increased by 8%.</p>",
      "crime_perception": "<p>A recent poll shows that fear of crime is at an all-time high, even though overall crime rates in the country are half of what they were 20 years ago. Your popular article <strong>{{articleTitle}}</strong> could be contributing to this.</p>",
      "health_advice": "<p>Your article <strong>{{articleTitle}}</strong> seems to be catching on! People seem worried for their health, and some are acting on the questionable advice in your article.</p>",
      "person_got_fired": "<p>According to the (not fake) news, a person just got fired, allegedly for sharing the inaccurate information your article <strong>{{articleTitle}}</strong>.</p>"
    },
    "help": {
      "prompt": "Hey there!",
      "initial_instructions": "<p>You will be making money by creating news sites and profiting when people view and click on ads on your site. It's pretty easy as long as you can generate enough traffic. </p><p>Your sites are going to be targeting people in the United States. Why? Well, because views and clicks from people in this country are paid at a higher rate than in other countries. You might not care about American politics, but you can still use its drama to profit!</p><p>While we are going to be creating \"news\" sites, we won't worry too much about sticking to the truth. Fake news takes less time to create, and it often spreads better than real news, since you aren’t as constrained by facts.</p><p>To help keep on you track, I'll give you some small goals to help you on your way.</p>",
      "copy_prompt": "Click here for important information about copying articles.",
      "copyArticles": "<p>You can copy existing articles from other questionable news outlets. This is a quick way to get started! When selecting articles, pay attention to the article's main topics, believability, and drama.</p><p><i class=\"ui tags icon\"></i><strong>Articles topics</strong> are the main themes of the article. If a topic has a color, it means that it's slanted to a particular political party. For example <span class=\"ui orange tag label\">Conspiracy</span> refers to a consipiracy that appeals to orange party members while <span class=\"ui purple tag label\">Conspiracy</span> tags would appeal to purple party members.</p><p><span class=\"ui large label\">Believability</span> is a reflection of whether a friendly audience would be likely to believe the article is true. People will believe lots of stuff, so it's not necessarily a reflection of accuracy.</p><p><span class=\"ui large label\">Drama</span> reflects how likely people are to care about (and share) the article.</p>",
      "plant_prompt": "Click here for important information about planting articles.",
      "plantArticles": "<div class=\"ui mini steps\"><div class=\"step\"><i class=\"newspaper icon\"></i><div class=\"content\"><div class=\"title\">Your Article</div></div></div><div class=\"step\"><i class=\"user icon\"></i><div class=\"content\"><div class=\"title\">SocialMedia User Profile</div></div></div><div class=\"step\"><i class=\"users icon\"></i><div class=\"content\"><div class=\"title\">SocialMedia Group</div></div></div></div><p>You will make a \"plant\" by sharing an article with a user profile to a particular group. Try to select a combination that will generate enough likes and shares to begin to spread through SocialMedia. If the article gets enough attention and views, you will start to earn money!</p>",
      "write_prompt": "Click here for important information about writing articles.",
      "writeArticles": "<p>Writing articles takes more effort than just copying them, but it gives you the ability to match trending topics and target certain emotions.</p><div class=\"ui mini steps\"><div class=\"step\"><i class=\"lab icon\"></i><div class=\"content\"><div class=\"title\">Article Base</div></div></div><div class=\"step\"><i class=\"wizard icon\"></i><div class=\"content\"><div class=\"title\">Article Supports (0 to 4)</div></div></div><div class=\"step\"><i class=\"announcement icon\"></i><div class=\"content\"><div class=\"title\">Title</div></div></div></div><p><strong>An article base</strong> is the foundation of your article – think of it as your inspiration! You can find potential bases from real-life events, common fears, SocialMedia posts, etc.</p><p><strong>Supports</strong> are techniques that you will use as you are writing your article, mostly to build believability and drama. You can add up to four supports to an article and will probably want to experiment to get the right combination.</p><p>Some supports add and twist article topics. For example, the topic <span class=\"ui tag label\">Veterans</span> could be twisted into <span class=\"ui tag orange label\">Veterans Are Being Mistreated</span> or <span class=\"ui tag purple label\">Veterans Are Being Mistreated</span> given the application of the right supports.</p><p>You know what a <strong>title</strong> is, right? You want your title to create a sense of urgency. Capital letters and extreme statements are encouraged!</p>"
    },
    "labels": {
      "likes": "Likes",
      "revenue": "Revenue",
      "shares": "Shares",
      "views": "Views",
      "believability": "Believability",
      "drama": "Drama",
      "debunked": "Debunked",
      "selected": "Selected",
      "too_expensive": "Too Expensive",
      "article_topics": "Article Topics",
      "base_topics": "Base Topics",
      "political_slant": "Political Slant",
      "neutral": "Neutral",
      "orange_party": "Orange Party",
      "purple_party": "Purple Party",
      "request_to_join": "Request To Join",
      "pending_approval": "Pending Approval",
      "rejected": "Rejected",
      "already_planted": "Already Planted",
      "members": "Members",
      "group_post_topics": "Group Post Topics",
      "continue": "Continue",
      "got_it": "Got It!",
      "next": "Next",
      "locked": "Locked",
      "articles": "Articles",
      "credibility": "Credibility",
      "your_plant": "Your plant",
      "article": "Article",
      "group": "Group",
      "profile": "Profile",
      "friends": "Friends",
      "emotions_triggered": "Emotions Triggered",
      "additional_results_details": "Additional results details",
      "trending_topic": "Trending Topic",
      "trending_topics": "Trending Topics",
      "political_match": "Political Match",
      "topic_match": "Topic Match",
      "delete": "Delete",
      "yes": "Yes",
      "no": "No",
      "play": "Play",
      "reason": "Reason",
      "advice": "Advice",
      "lets_go": "Let's Go!",
      "try_this": "Try This!"
    },
    "filters": {
      "filter_by": "Filter by:",
      "sort_by": "Sort by:",
      "source": "Source",
      "topic": "Topic",
      "recent": "Recent",
      "popular": "Popular",
      "all_sources": "All sources",
      "political_party": "Political Party",
      "all_political_tags": "All Political Tags",
      "no_matching_bases": "No article bases match your filters.",
      "no_matching_items": "No items match your filters.",
      "change_filter_prompt": "Change or remove filters to expand your search."
    },
    "copy_articles": {
      "header": "Copy Articles",
      "instructions": "Select the articles below that you would like to copy to {{siteName}}.",
      "is_incomplete": "Select at least one article to copy",
      "is_ready": "Click Here to Copy Articles ",
      "time_required": "It will take {{timeRequired}} hours for you to copy and reformat these articles.",
      "copy_message_one": "Copying 1 article",
      "copy_message_multiple": "Copying {{number}} articles.",
      "copying_articles": "Copying Article..."
    },
    "create_profile": {
      "header": "Create A New (Fake) SocialMedia Profile",
      "select_photo": "Select Photo",
      "name_placeholder": "Fake name (i.e. Joe Bogs)",
      "hours_to_spend": "Hours To Spend Customizing This Profile",
      "button": {
        "is_complete": "Create Profile",
        "is_incomplete": "Complete All Fields Above"
      },
      "customization_options": {
        "low": "Low - 1 hour",
        "medium": "Medium - 3 hours",
        "high": "High - 5 hours"
      },
      "customizing_profile": "Customizing Profile"
    },
    "menu": {
      "site_dashboard": "Site Dashboard",
      "copy_articles": "Copy Articles",
      "write_an_article": "Write An Article",
      "plant_an_article": "Plant An Article",
      "all_sites": "All Sites",
      "options_pause": "Options / Pause",
      "options": "Options",
      "options_information": "Your game is paused, and your progress been automatically saved. You can access saved games by going to the main menu.",
      "back_to_current_game": "Back To Current Game",
      "go_to_main_menu": "Go To Main Menu",
      "go_to_about": "About Fake News And This Game",
      "you_have": "You have",
      "show_expenses": "Show Expenses",
      "monthly_hosting_fee": "Monthly Hosting Fee",
      "upcoming_expenses": "Upcoming Expenses",
      "none_yet": "None yet",
      "recent_expenses": "Recent Expenses",
      "goal_progress": "You have {{percentGoalComplete}}% of your goal of ${{gameGoalCost}}",
      "goal_complete": "You met your goal! Hooray!",
      "start_a_new_game": "Start A New Game"
    },
    "write_article": {
      "support_tabs": {
        "general_drama": "General Drama",
        "believability": "Believability",
        "orange_party_drama": "Orange Party Drama",
        "purple_party_drama": "Purple Party Drama"
      },
      "too_many_bases": "You can only have one article base. If you want to add a different base, first remove the base on the right.",
      "too_many_supports": "You have already added the maximum number of supports. If you want to add a different support, first remove one of the supports on the right.",
      "writing_article": "Writing Article"
    },
    "goals_display": {
      "header": "Goals",
      "hide_completed_goals": "Hide Completed Goals",
      "show_completed_goals": "Show Completed Goals",
      "hint_prompt": "You might be struggling with your current goal:<ul><li>{{goalText}}</li></ul>" + "If you would like a hint, click the goal in your Goals dialog box for some additional information.",
      "next_goal_introduction": "Your next goal is to:",
      "next_goal_introduction_2": "You can launch these instructions again, with additional tips, by clicking the goal in the <strong>Goals</strong> dialog.",
      "final_goal_complete": "I think you've got the process down, so I'm going to stop giving you intermediate goals. Keep up the good work!"
    },
    "site_dashboard": {
      "header": "Dashboard for",
      "confirm_delete": "Are you sure that you want to delete this article? This cannot be undone!",
      "no_articles": "This site doesn't have any articles yet!",
      "copy_prompt": "Click the Copy Articles menu item above.",
      "copy_write_prompt": "Use the menu items above to either copy or write some articles.",
      "site_credibility": "Site Credibility",
      "article_believability": "Article Believability",
      "political_congruity": "Political Congruity",
      "site_looks_established": "Site Looks Established",
      "debunked_articles": "Debunked Articles",
      "advice": {
        "theme": "Go to Site Settings below to see options for changing your theme.",
        "domain_and_hosting": "Go to Site Settings below to see options for changing your domain and hosting.",
        "believability": "Check the believability ratings of the articles on this site. If people see something on your site that they know to be true, they are more likely to believe the rest.",
        "political_congruity": "In today's politically divided United States, your site will generally be more successful if it is geared toward one political party or the other.",
        "established": "Your site looks more established and reputable if it has more articles.",
        "debunked": "Fact-checking sites may debunk your more-popular articles, reducing the overall credibility of your site. Luckily, it has become popular to disparage the credibility of fact-checking sites, so some people won't care."
      },
      "site_settings": "Site Settings",
      "theme": {
        "header": "Theme",
        "information": "If you would like to use a different theme for your site, select one below. Any cost will be immediately deducted from your account, so choose carefully!"
      },
      "domain_and_hosting": {
        "header": "Domain and Hosting",
        "information": "If you would like to use a different domain and hosting option for your site, select one below. Any cost will be immediately deducted from your account, so choose carefully!"
      }
    },
    "loan": {
      "loan_repayment": "Loan repayment",
      "loan_terms": "Uh oh! You ran out of money.<br><br>I am willing to offer you a ${{loanAmount}} loan to cover your outstanding expenses, but you will need to repay me ${{repaymentAmount}} in one month. Agreed?"
    },
    "move_article": {
      "header": "Move Article",
      "instructions": "You can move the article <span class=\"bold\">{{articleTitle}}</span> to another site. The article's displayed likes, shares, views, and revenue will all be set to 0, since this article will be associated with a new web address. You will keep the money you have already earned for <span class=\"bold\">{{siteName}}</span>.",
      "cancel_move": "Nevermind! Leave the article on {{siteName}}",
      "select_site_instructions": "Select the site where you would like to move the article."
    },
    "share_and_copyright": {
      "share_request": "If you enjoy this game, please consider sharing it:",
      "game_title": "Fake It To Make It",
      "long_description": "Create your own (fake) news empire by playing Fake It To Make It, a simulation-style, social-impact game about how fake news is created and distributed for a profit.",
      "short_description": "Create your own (fake) news empire by playing Fake It To Make It, a simulation-style game.",
      "copyright": "  <p>Created by Amanda Warner of <a href=\"https://www.curiousbird.se/\" target=\"_blank\">Curious Bird</a>. Keep in touch by <a href=\"https://www.curiousbird.se/contact\" target=\"_blank\">joining our mailing list.</a></p>"
    },
    "winning": {
      "header": "You Won!",
      "description": "<p>Nice work! You met your goal of having enough money to {{gameGoal}}.</p><p>You don’t want to fall for the type of stuff that you just created, do you? Click the button below to learn more about real-life fake news and the purpose behind this game.</p>",
      "can_return_prompt": "(Even after you click the button above, you will be able to come back and continue your game, if you’d like.)"
    },
    "about": {
      "q1": {
        "question": "How can I be better informed about fake news and other forms of misinformation?",
        "answer": "<p>I'm so glad you asked! There are a number of good articles on how to identify fake news, including this one from FactCheck.org: <a href=\"http://www.factcheck.org/2016/11/how-to-spot-fake-news/\" target=\"_blank\">How to Spot Fake News</a>. In addition, please: </p><p><strong>Do your research before sharing!</strong> If you see something questionable, look it up! Sometimes, just a quick Google search is enough. Otherwise, I recommend <a href=\"http://www.snopes.com/\" target=\"_blank\">Snopes</a>, <a href=\"http://www.politifact.com/\" target=\"_blank\">Politifact</a>, and <a href=\"http://www.factcheck.org/\" target=\"_blank\">FactCheck.org</a>, all of which regularly debunk fake news and other types of misinformation.</p><p><strong>Consider your sources.</strong> If you use Chrome, try the plug-in <a href=\"https://chrome.google.com/webstore/detail/fake-news-monitor/abchpppcldemnoihgbnodlbdbnibcjan?hl=en\">Fake News Monitor</a>, which will alert you if you are visiting certain sites known to distribute questionable news. It also lists the political leanings of sites.</p><p><strong>Ask yourself if someone is benefiting by manipulating your emotions.</strong> Just like in the game, people may generate views and profits by promoting fear, anger, and other strong emotions that generate shares. If you find an article upseting, that's even more reason to do your research.</p>"
      },
      "q2": {
        "question": "What real-life information inspired this game?",
        "answer": "<p>While some concepts have been simplified for the sake of playability, the overall idea is based on real life events, particularly the Macedonian teeangers who profited from fake news leading up to the 2016 election in the United States. To learn more about the type of fake-news-distribution-for-a-profit that inspired this game, I recommend:</p><ul><li><a href=\" http://www.npr.org/2016/12/14/505547295/fake-news-expert-on-how-false-stories-spread-and-why-people-believe-them\" target=\"_blank\">Fake News Expert On How False Stories Spread And Why People Believe Them</a></li><li><a href=\"https://www.buzzfeed.com/craigsilverman/how-macedonia-became-a-global-hub-for-pro-trump-misinfo?utm_term=.kuvlVDdZV5#.auv1eworeD\" target=\"_blank\">How Teens In The Balkans Are Duping Trump Supporters With Fake News</a></li><li><a href=\"http://www.npr.org/sections/alltechconsidered/2016/11/23/503146770/npr-finds-the-head-of-a-covert-fake-news-operation-in-the-suburbs\" target=\"_blank\">We Tracked Down A Fake-News Creator In The Suburbs. Here's What We Learned</a></li><li><a href=\"https://www.theguardian.com/media/2017/feb/06/liberal-fake-news-shift-trump-standing-rock\" target=\"_blank\">Fake news for liberals: misinformation starts to lean left under Trump</a></li><li><a href=\"http://www.pbs.org/newshour/updates/real-consequences-fake-news-stories-brain-cant-ignore/\" target=\"_blank\">The very real consequences of fake news stories and why your brain can’t ignore them</a></li></ul>"
      },
      "q3": {
        "question": "Who created this game?",
        "answer": "<p>Hi there!</p><p>I’m Amanda Warner, the designer and developer of Fake It To Make It. You can read more about me and my work at <a href=\"http://www.amanda-warner.com\" target=\"_blank\">http://www.amanda-warner.com</a>. You can send suggestions to feedback@fakeittomakeitgame.com</p><p>Robbie Dooley created the music for Fake It To Make It. You can learn more about Robbie at <a href=\"http://www.robbiedooley.com\">http://www.robbiedooley.com</a></p>"
      },
      "q4": {
        "question": "May I use this game in my class/presentation/wedding?",
        "answer": "<p>Yes please! The game is publically available to present or use, inside or outside of a classroom.</p><p>I always appreciate knowing how the game is being used and receiving any feedback. You can send me an email at feedback@fakeittomakeitgame.com. If you are a teacher or professor using this game in your classes, I would be particularly excited to hear from you.</p>"
      },
      "q5": {
        "question": "Why did you create this game? Are you teaching people to write fake news?",
        "answer": "<p><strong>Short answer:</strong> I hope not.</p><p><strong>Longer answer:</strong> The process of creating fake news is already well documented online. If someone wants to make a fake news site, they already have access to the information they need. However, I acknowledge that there is a difference between information and inspiration. It's possible that this game could inspire someone to make fake news, but I'm willing to take the risk, because I think the potential for positive change in players is worth it.</p><p>My hope is that by making players more aware of how and why fake news is written and distributed, that they will be more skeptical of what they encounter in the future. In general, I think that better understanding how and why we are manipulated by others, for profit or power, is worthwhile knowledge to have.</p><p>The image below shows the actions, attitudes, and beliefs I would like to see in players of this game.</p>"
      },
      "q6": {
        "question": "Where has this game been covered in the (not fake) media?",
        "answer": "<ul><li><a href=\"http://kotaku.com/fake-news-video-game-is-a-little-too-real-1793660926\">Fake News Video Game Is A Little Too Real</a> by Luke Plunkett, Kotaku</li><li><a href=\"http://www.theverge.com/2017/3/27/15072374/fake-it-to-make-it-browser-game-fake-news\">Live a day in the life of a fake news creator</a> by Megan Farokhmanesh, The Verge </li><li><a href=\"http://www.spiegel.de/netzwelt/games/fake-it-to-make-it-erschreckend-realistisches-browserspiel-a-1141411.html\">Lügen, hetzen, Gewinne einfahren</a> by Jörg Breithut, Der Spiegel</li><li><a href=\"http://www.sueddeutsche.de/digital/browser-spiel-fake-it-to-make-it-fake-news-zum-selberbauen-1.3450453\">Fake News zum Selberbauen</a> by Yannic Hannebohn, Suddeutsche Zeitung</li><li><a href=\"http://ici.radio-canada.ca/nouvelle/1025030/fausses-nouvelles-jeu-video-educatif\">Un jeu qui sensibilise aux fausses nouvelles</a> by Maxence Matteau, Radio Canada</li><li><a href=\"http://www.20minutes.fr/high-tech/2041319-20170331-jeu-fake-it-to-make-it-peut-etre-efficace-sensibiliser-jeunes-fake-news\">Le jeu «Fake it to make it» peut-il être efficace pour sensibiliser les jeunes aux fake news?</a> by Delphine Bancaud, 20 Minutes</li><li><a href=\"http://www.ibtimes.co.uk/fake-it-make-it-scarily-realistic-game-that-simulates-spread-viral-clickbait-fake-news-1614167\">Fake It To Make It: The scarily realistic game that simulates spread of viral clickbait fake news</a> by Mary-Ann Russon, International Business Times</li><li><a href=\"http://wgnradio.com/2017/04/01/saturday-night-special-with-amy-guth-4117-media-literacy/\">Media Literacy, Saturday Night Special</a> with Amy Guth on WGN Radio Chicago</li><li><a href=\"http://www.newstalk.com/podcasts/The_Green_Room/Highlights_from_The_Green_Room/186311/How_does_fake_news_work\">How Does Fake News Work, The Green Room</a> with Orla Barry on NewstalkFM</li></ul>"
      }
    }
  };
});
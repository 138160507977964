define('fakenews/components/group-summary', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['ui', 'card'],
    actionEnabled: true,
    awaitingAction: _ember['default'].computed.not('actionTaken'),
    actionTaken: _ember['default'].computed.or('isPending', 'isRejected', 'isJoined'),
    isPending: _ember['default'].computed('gameData.currentProfile.pendingGroups', function () {
      return this.get('gameData.currentProfile.pendingGroups').includes(this.get('model'));
    }),
    isRejected: _ember['default'].computed('gameData.currentProfile.rejectedGroups', function () {
      return this.get('gameData.currentProfile.rejectedGroups').includes(this.get('model'));
    }),
    isJoined: _ember['default'].computed('gameData.currentProfile.groups', function () {
      return this.get('gameData.currentProfile.groups').includes(this.get('model'));
    })

  });
});
define('fakenews/initializers/game-data', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('route', 'gameData', 'service:game-data');
    application.inject('controller', 'gameData', 'service:game-data');
    application.inject('component', 'gameData', 'service:game-data');
  }

  exports['default'] = {
    name: 'game-data',
    initialize: initialize
  };
});
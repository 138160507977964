define('fakenews/components/winning-modal', ['exports', 'ember', 'semantic-ui-ember/components/ui-modal'], function (exports, _ember, _semanticUiEmberComponentsUiModal) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    name: 'winning',
    classNames: ['winning'],
    routing: _ember['default'].inject.service('-routing'),

    actions: {
      closeModal: function closeModal() {
        $('.ui.winning.modal').modal('hide');
      },
      backToIndex: function backToIndex() {
        this.get("routing").transitionTo("index");
        $('.ui.menu.modal').modal('hide');
      },
      learnMore: function learnMore() {
        this.get("routing").transitionTo("about");
        $('.ui.winning.modal').modal('hide');
      }
    }
  });
});
define("fakenews/templates/components/opening-options", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 39
            },
            "end": {
              "line": 14,
              "column": 90
            }
          },
          "moduleName": "fakenews/templates/components/opening-options.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["home_page.paragraph_1_2"], [], ["loc", [null, [14, 59], [14, 90]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 16
                },
                "end": {
                  "line": 40,
                  "column": 16
                }
              },
              "moduleName": "fakenews/templates/components/opening-options.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "ui trash icon");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", ["labels.delete"], [], ["loc", [null, [39, 48], [39, 69]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 6
              },
              "end": {
                "line": 51,
                "column": 8
              }
            },
            "moduleName": "fakenews/templates/components/opening-options.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "raised card");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "content");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("img");
            dom.setAttribute(el3, "class", "right floated mini ui image");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "header");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "meta");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "description");
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode(" ");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n              ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "ui bottom attached buttons");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "class", "ui teal button");
            dom.setAttribute(el3, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "ui newspaper icon");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode(" ");
            dom.appendChild(el3, el4);
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "ui popup");
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("p");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                  ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "ui fluid buttons");
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5, "class", "ui purple button");
            dom.setAttribute(el5, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5, "class", "or");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                    ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("div");
            dom.setAttribute(el5, "class", "ui teal button");
            dom.setAttribute(el5, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
            var el6 = dom.createComment("");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n                  ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n                ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [1]);
            var element2 = dom.childAt(element1, [1]);
            var element3 = dom.childAt(element2, [1]);
            var element4 = dom.childAt(element2, [5]);
            var element5 = dom.childAt(element2, [7, 1]);
            var element6 = dom.childAt(element1, [3]);
            var element7 = dom.childAt(element6, [1]);
            var element8 = dom.childAt(element6, [5]);
            var element9 = dom.childAt(element8, [3]);
            var element10 = dom.childAt(element9, [1]);
            var element11 = dom.childAt(element9, [5]);
            var morphs = new Array(14);
            morphs[0] = dom.createAttrMorph(element3, 'src');
            morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
            morphs[2] = dom.createMorphAt(element4, 1, 1);
            morphs[3] = dom.createMorphAt(element4, 3, 3);
            morphs[4] = dom.createMorphAt(element5, 0, 0);
            morphs[5] = dom.createMorphAt(element5, 2, 2);
            morphs[6] = dom.createElementMorph(element7);
            morphs[7] = dom.createMorphAt(element7, 3, 3);
            morphs[8] = dom.createMorphAt(element6, 3, 3);
            morphs[9] = dom.createMorphAt(dom.childAt(element8, [1]), 0, 0);
            morphs[10] = dom.createElementMorph(element10);
            morphs[11] = dom.createMorphAt(element10, 0, 0);
            morphs[12] = dom.createElementMorph(element11);
            morphs[13] = dom.createMorphAt(element11, 0, 0);
            return morphs;
          },
          statements: [["attribute", "src", ["subexpr", "concat", ["../../../images/", ["get", "game.gameGoal.imgSrc", ["loc", [null, [23, 85], [23, 105]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [23, 107]]], 0, 0], 0, 0, 0, 0], ["content", "game.userName", ["loc", [null, [25, 16], [25, 33]]], 0, 0, 0, 0], ["inline", "t", ["home_page.last_played"], [], ["loc", [null, [28, 16], [28, 45]]], 0, 0], ["inline", "moment-format", [["get", "game.lastPlayed", ["loc", [null, [28, 62], [28, 77]]], 0, 0, 0, 0], "MMMM D, YYYY"], [], ["loc", [null, [28, 46], [28, 94]]], 0, 0], ["inline", "t", ["home_page.total_funds"], [], ["loc", [null, [31, 21], [31, 50]]], 0, 0], ["inline", "format-number", [["get", "game.revenue", ["loc", [null, [31, 67], [31, 79]]], 0, 0, 0, 0]], ["format", "$0.00"], ["loc", [null, [31, 51], [31, 96]]], 0, 0], ["element", "action", ["selectGame", ["get", "game", ["loc", [null, [35, 69], [35, 73]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 47], [35, 75]]], 0, 0], ["inline", "t", ["labels.play"], [], ["loc", [null, [36, 52], [36, 71]]], 0, 0], ["block", "ui-popup", [], ["tagName", "button", "on", "click", "actionEnabled", true, "position", "top center", "lastResort", "top center", "class", "deletePopUp ui purple button"], 0, null, ["loc", [null, [38, 16], [40, 29]]]], ["inline", "t", ["home_page.delete_confirm"], [], ["loc", [null, [42, 21], [42, 53]]], 0, 0], ["element", "action", ["deleteGame", ["get", "game", ["loc", [null, [44, 72], [44, 76]]], 0, 0, 0, 0]], [], ["loc", [null, [44, 50], [44, 78]]], 0, 0], ["inline", "t", ["labels.yes"], [], ["loc", [null, [44, 79], [44, 97]]], 0, 0], ["element", "action", ["closePopup", ["get", "game", ["loc", [null, [46, 70], [46, 74]]], 0, 0, 0, 0]], [], ["loc", [null, [46, 48], [46, 76]]], 0, 0], ["inline", "t", ["labels.no"], [], ["loc", [null, [46, 77], [46, 94]]], 0, 0]],
          locals: ["game"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 4
            },
            "end": {
              "line": 72,
              "column": 4
            }
          },
          "moduleName": "fakenews/templates/components/opening-options.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "ui three cards");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "class", "link card");
          dom.setAttribute(el2, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "ui content");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("br");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("br");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("br");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("br");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "ui active teal dimmer");
          var el5 = dom.createTextNode("\n              ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          dom.setAttribute(el5, "class", "content");
          var el6 = dom.createTextNode("\n                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createElement("div");
          dom.setAttribute(el6, "class", "center");
          var el7 = dom.createTextNode("\n                  ");
          dom.appendChild(el6, el7);
          var el7 = dom.createElement("h3");
          dom.setAttribute(el7, "class", "ui inverted icon header");
          var el8 = dom.createTextNode("\n                    ");
          dom.appendChild(el7, el8);
          var el8 = dom.createElement("i");
          dom.setAttribute(el8, "class", "plus icon");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                    ");
          dom.appendChild(el7, el8);
          var el8 = dom.createComment("");
          dom.appendChild(el7, el8);
          var el8 = dom.createTextNode("\n                  ");
          dom.appendChild(el7, el8);
          dom.appendChild(el6, el7);
          var el7 = dom.createTextNode("\n                ");
          dom.appendChild(el6, el7);
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n              ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element12 = dom.childAt(fragment, [3]);
          var element13 = dom.childAt(element12, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element12, 1, 1);
          morphs[2] = dom.createElementMorph(element13);
          morphs[3] = dom.createMorphAt(dom.childAt(element13, [1, 9, 1, 1, 1]), 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["home_page.your_existing_games"], [], ["loc", [null, [18, 7], [18, 44]]], 0, 0], ["block", "each", [["get", "displayedGames", ["loc", [null, [20, 14], [20, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [20, 6], [51, 17]]]], ["element", "action", ["startNewGame"], [], ["loc", [null, [52, 29], [52, 54]]], 0, 0], ["inline", "t", ["menu.start_a_new_game"], [], ["loc", [null, [63, 20], [63, 49]]], 0, 0]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 72,
              "column": 4
            },
            "end": {
              "line": 76,
              "column": 4
            }
          },
          "moduleName": "fakenews/templates/components/opening-options.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "class", "ui teal fluid button");
          dom.setAttribute(el1, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "ui newspaper icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element0);
          morphs[1] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["element", "action", ["startNewGame"], [], ["loc", [null, [73, 43], [73, 68]]], 0, 0], ["inline", "t", ["menu.start_a_new_game"], [], ["loc", [null, [74, 42], [74, 71]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 82,
            "column": 0
          }
        },
        "moduleName": "fakenews/templates/components/opening-options.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ui main container");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "ui grid");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "ui mobile only row");
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "ui blue inverted raised segment");
        dom.setAttribute(el4, "style", "width:400px");
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("h2");
        var el6 = dom.createTextNode("Greetings! Just a quick warning that this game is designed for a wider browser.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("p");
        var el6 = dom.createTextNode("Fake It To Make It does technically work in mobile browsers, but you will probably be much happier playing on something wider, like a computer or a tablet, instead of a phone.");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n    ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n  ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("br");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "ui raised segment");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("img");
        dom.setAttribute(el3, "src", "images/wide_game_logo.png");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "ui divider");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("p");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "ui divider");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "ui divider");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element14 = dom.childAt(fragment, [0, 5]);
        var element15 = dom.childAt(element14, [5]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(element15, 0, 0);
        morphs[1] = dom.createMorphAt(element15, 2, 2);
        morphs[2] = dom.createMorphAt(element15, 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element14, [7]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element14, [9]), 0, 0);
        morphs[5] = dom.createMorphAt(element14, 11, 11);
        morphs[6] = dom.createMorphAt(element14, 15, 15);
        return morphs;
      },
      statements: [["inline", "t", ["home_page.paragraph_1_1"], [], ["loc", [null, [14, 7], [14, 38]]], 0, 0], ["block", "link-to", ["about"], [], 0, null, ["loc", [null, [14, 39], [14, 102]]]], ["inline", "t", ["home_page.paragraph_1_3"], [], ["loc", [null, [14, 102], [14, 133]]], 0, 0], ["inline", "t", ["home_page.paragraph_2"], [], ["loc", [null, [15, 7], [15, 36]]], 0, 0], ["inline", "t", ["home_page.paragraph_3"], [], ["loc", [null, [16, 7], [16, 36]]], 0, 0], ["block", "if", [["get", "displayedGames", ["loc", [null, [17, 10], [17, 24]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [17, 4], [76, 11]]]], ["content", "share-request", ["loc", [null, [78, 4], [78, 21]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});
define('fakenews/storages/game-data', ['exports', 'ember-local-storage/local/object'], function (exports, _emberLocalStorageLocalObject) {

  var Storage = _emberLocalStorageLocalObject['default'].extend();

  Storage.reopenClass({
    /*   initialState() {
         return {
           day: 0,
           hour: 0,
           funds: 0
         };
       }*/
  });

  exports['default'] = Storage;
});
define('fakenews/components/bases-display', ['exports', 'ember', 'ember-cli-pagination/computed/paged-array', 'ember-cli-filter-by-query/util/filter'], function (exports, _ember, _emberCliPaginationComputedPagedArray, _emberCliFilterByQueryUtilFilter) {
  exports['default'] = _ember['default'].Component.extend({
    page: 1,
    perPage: 3,
    totalPagesBinding: "pagedContent.totalPages",

    hasMultiplePages: _ember['default'].computed.gt('pagedContent.totalPages', 1),

    pagedContent: (0, _emberCliPaginationComputedPagedArray['default'])('filteredContent', { pageBinding: "page", perPageBinding: "perPage" }),

    noneToDisplay: _ember['default'].computed.lt('filteredContent.length', 1),

    filteredContent: _ember['default'].computed('bases.@each.politicalSlant', 'selectedSource', 'selectedParty', 'selectedTopic', 'topicInput', function () {
      var matchingItems = this.get('bases');
      this.set('page', 1);

      if (this.get('selectedSource') !== "any") {
        matchingItems = matchingItems.filterBy('category', this.get('selectedSource'));
      }

      if (this.get('selectedParty') !== "any") {
        matchingItems = matchingItems.filterBy('politicalSlant', parseInt(this.get('selectedParty')));
      }

      if (this.get('selectedTopic') !== "any") {
        matchingItems = matchingItems.filter(function (item) {
          return item.get('tags').mapBy('category').includes(this.get('selectedTopic'));
        }, this);
      }
      if (this.get('topicInput') !== '') {
        matchingItems = matchingItems.filter(function (item) {
          var results = (0, _emberCliFilterByQueryUtilFilter['default'])(item.get('tags'), ['name', 'subject', 'topic'], this.get('topicInput'));
          return results.get('length') > 0;
        }, this);
      }

      return matchingItems;
    }),

    selectedSource: "any",
    selectedParty: "any",
    selectedTopic: "any",

    possibleSource: _ember['default'].computed('bases', function () {
      return this.get('bases').mapBy('category').uniq();
    }),

    possibleTopics: _ember['default'].computed('bases.@each.tags', function () {
      var tagNames = [];
      this.get('bases').forEach(function (base) {
        base.get('tags').forEach(function (tag) {
          tagNames.addObject(tag.get('category'));
        });
      });
      tagNames = tagNames.uniq();

      return tagNames;
    }),

    topicInput: ''
  });
});
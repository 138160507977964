define('fakenews/models/user-profile', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  exports['default'] = _emberData['default'].Model.extend({
    game: _emberData['default'].belongsTo('game'),
    copiedFrom: _emberData['default'].belongsTo('profile'),

    name: _emberData['default'].attr('string'),
    photo: _emberData['default'].attr('string'),
    tags: _emberData['default'].hasMany('tag'),

    groups: _emberData['default'].hasMany('group', { inverse: 'userProfiles' }),
    pendingGroups: _emberData['default'].hasMany('group', { inverse: 'pendingMembers' }),
    rejectedGroups: _emberData['default'].hasMany('group', { inverse: 'rejectedMembers' }),

    sortedByPlantedGroups: _ember['default'].computed.sort('groups.@each.alreadyPlanted', function (itemA, itemB) {
      if (itemA.get('alreadyPlanted') === itemB.get('alreadyPlanted')) {
        return 0;
      } else if (itemA.get('alreadyPlanted')) {
        return 1;
      } else {
        return -1;
      }
    }),

    credibility: _emberData['default'].attr('number'),
    friends: _emberData['default'].attr('number', { defaultValue: 0 }),

    saved: _emberData['default'].attr('boolean', { defaultValue: false }),
    politicalSlant: _emberData['default'].attr('number'),

    userPlants: _emberData['default'].hasMany('user-plant')

  });
});
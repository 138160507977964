define('fakenews/models/site', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  var Model = _emberData['default'].Model;
  var attr = _emberData['default'].attr;
  var hasMany = _emberData['default'].hasMany;
  var belongsTo = _emberData['default'].belongsTo;
  exports['default'] = Model.extend({
    name: attr('string'),
    logo: attr('string'),
    likes: attr('number', { defaultValue: 0 }),
    shares: attr('number', { defaultValue: 0 }),
    views: attr('number', { defaultValue: 0 }),
    revenue: attr('number', { defaultValue: 0 }),

    saved: _emberData['default'].attr('boolean', { defaultValue: false }),

    //Can change. Credibility should come from here
    theme: attr(''),
    domainAndHosting: attr(''),
    monitization: attr(''),

    credibility: _ember['default'].computed('theme.credibility', 'domainAndHosting.credibility', 'politicalCongruityCredibility', 'looksEstablishedCredibility', 'articleBelievabilityCredibility', 'articlesDebunkedCredibility', function () {
      return this.getWithDefault('theme.credibility', 0) + this.getWithDefault('domainAndHosting.credibility', 0) + this.getWithDefault('politicalCongruityCredibility', 0) + this.getWithDefault('looksEstablishedCredibility', 0) + this.getWithDefault('articleBelievabilityCredibility', 0) + this.getWithDefault('articlesDebunkedCredibility', 0);
    }),

    numberOfDebunkedArticles: _ember['default'].computed('articles.@each.debunked', function () {
      return this.get('articles').filterBy('debunked').get('length');
    }),

    articlesDebunkedCredibility: _ember['default'].computed('numberOfDebunkedArticles', function () {
      return -Math.abs(this.get('numberOfDebunkedArticles') * 5);
    }),

    purpleArticles: _ember['default'].computed.filterBy('articles', 'politicalSlant', 2),
    orangeArticles: _ember['default'].computed.filterBy('articles', 'politicalSlant', 1),
    grayArticles: _ember['default'].computed.filterBy('articles', 'politicalSlant', 0),

    // If at least 95 of tags on the site are either one party or neutral, then +10. Else +0
    politicalCongruityCredibility: _ember['default'].computed('articles.@each.politicalSlant', function () {
      var credibility = 0;

      var articles = this.get('articles');
      var totalArticles = articles.get('length');

      if (_ember['default'].isPresent(articles)) {
        var numberOfOrange = this.get('orangeArticles').get('length');
        var numberOfPurple = this.get('purpleArticles').get('length');

        var percentOrange = numberOfOrange / totalArticles;
        var percentPurple = numberOfPurple / totalArticles;

        if (percentOrange < 0.05 || percentPurple < 0.05) {
          credibility = 10;
        }
      }

      return credibility;
    }),

    // If more than 8 articles, number of articles. Max bonus of 20.
    looksEstablishedCredibility: _ember['default'].computed('totalArticles', function () {
      var credibility = 0;
      var totalArticles = this.get('totalArticles');
      if (totalArticles > 2) {
        credibility = totalArticles;
        if (credibility > 20) {
          credibility = 20;
        }
      }

      return credibility;
    }),

    // Average article believability
    articleBelievabilityCredibility: _ember['default'].computed('articles.@each.believability', function () {
      var credibility = 0;
      var articles = this.get('articles');
      var totalArticles = articles.get('length');

      if (totalArticles > 0) {

        var totalBelievability = articles.mapBy('believability').reduce(function (a, b) {
          return a + b;
        });

        credibility = Math.floor(totalBelievability / totalArticles);
      }

      return credibility;
    }),

    totalPlants: _ember['default'].computed.alias('plants.length'),

    plants: hasMany('user-plant', { dependent: 'destroy' }),

    totalArticles: _ember['default'].computed('articles', function () {
      return this.get('articles.length');
    }),

    articles: hasMany('user-article', { dependent: 'destroy' }),

    game: belongsTo('game')
  });
});
define('fakenews/components/plant-comments', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    planter: _ember['default'].inject.service(),
    displayedCommentBubbles: _ember['default'].computed.readOnly('planter.displayedCommentBubbles'),

    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      this.sendAction('resetSelectionsMode');
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this.get('planter.displayCommentBubbles').perform(this.get('plant'));
    }

  });
});
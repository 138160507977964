define('fakenews/models/profile', ['exports', 'ember-data', 'ember', 'fakenews/mixins/political-slant'], function (exports, _emberData, _ember, _fakenewsMixinsPoliticalSlant) {
  exports['default'] = _emberData['default'].Model.extend(_fakenewsMixinsPoliticalSlant['default'], {
    name: _emberData['default'].attr(),
    photo: _emberData['default'].attr(),
    tags: _emberData['default'].hasMany('tag'),
    groups: _emberData['default'].hasMany('group'),

    credibility: _emberData['default'].attr('number'),
    friends: _emberData['default'].attr('number'),
    cost: _ember['default'].computed(function () {
      return Math.ceil(this.get('credibility') / 10 + this.get('friends') / 100);
    }),

    politicalSlant: _emberData['default'].attr('number'),

    locked: _emberData['default'].attr('boolean', { defaultValue: true })
  });
});
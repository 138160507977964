define('fakenews/services/heartbeat', ['exports', 'ember', 'ember-local-storage'], function (exports, _ember, _emberLocalStorage) {
  exports['default'] = _ember['default'].Service.extend({
    storage: (0, _emberLocalStorage.storageFor)('game-data'),
    planter: _ember['default'].inject.service(),
    gameData: _ember['default'].inject.service(),
    money: _ember['default'].inject.service(),
    trendingTags: _ember['default'].inject.service(),
    heartbeatInterval: 3000,
    intervalCount: 0,
    changeInEffect: false,
    totalIntervals: 1,
    goals: _ember['default'].inject.service(),
    moment: _ember['default'].inject.service(),
    groups: _ember['default'].inject.service(),

    speedPercentComplete: _ember['default'].computed('intervalCount', function () {
      var percent = Math.floor(this.get('intervalCount') / this.get('totalIntervals') * 100);
      if (percent > 100) {
        percent = 100;
      }
      return percent;
    }),

    startHeartbeat: function startHeartbeat() {

      if (!(this.get('gameData.gamePaused') || _ember['default'].isBlank(this.get('gameData.currentGame')))) {
        if (this.get('changeInEffect')) {
          this.incrementProperty('intervalCount');
          if (this.get('intervalCount') > this.get('totalIntervals')) {
            this.revertHeartbeat();
          }
        } else {
          if (!this.get('gameData.currentGame.goalsCompleted') || !this.get('gameData.currentGame.hasWon')) {
            this.get('goals.checkGoals').perform();
          }
          if (this.get('gameData.currentGame.revenue') < 0) {
            this.get('money').launchLoanModal();
          }
        }
        this.get('groups.checkGroups').perform();
        this.get('planter.updateActivePlants').perform(this.get('changeInEffect'));

        this.updateTime();
      }
      var self = this;
      _ember['default'].run.later(function () {
        self.startHeartbeat();
      }, this.get('heartbeatInterval'));
    },

    endingFunction: null,
    caller: null,
    startSpeedHeartbeat: function startSpeedHeartbeat(howManyIntervals, endingFunction, caller) {
      this.setProperties({
        endingFunction: endingFunction,
        caller: caller,
        heartbeatInterval: 1000,
        intervalCount: 0,
        changeInEffect: true,
        totalIntervals: howManyIntervals
      });
    },
    revertHeartbeat: function revertHeartbeat() {
      this.get('endingFunction')(this.get('caller'));
      this.setProperties({
        heartbeatInterval: 3000,
        changeInEffect: false
      });
    },

    updateTime: function updateTime() {
      var moment = this.get('moment').moment(this.get('gameData.currentGame.date'));
      moment.add(1, 'hour');
      if (moment.hour() > 6) {
        if (Math.random() < 0.15) {
          this.get('trendingTags').updateTrendingTags();
        }
        moment.hour(0);
        moment.add(1, 'day');
        this.set('gameData.currentGame.date', new Date(moment.toString()));
        this.get('money').checkExpenses();
      } else {
        this.set('gameData.currentGame.date', new Date(moment.toString()));
      }
      this.set('gameData.currentGame.lastPlayed', this.get('moment').moment());
      this.get('gameData.currentGame').save();
    }
  });
});
define('fakenews/controllers/play/site/home', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    money: _ember['default'].inject.service(),
    i18n: _ember['default'].inject.service(),

    articlesSorted: _ember['default'].computed.sort('model.articles', 'articlesSortingDesc'),

    articlesSortingDesc: ['lastTouchDate:desc'],

    twoOrMoreSites: _ember['default'].computed.gte('gameData.currentGame.sites.length', 2),

    actions: {
      themeOptionSelected: function themeOptionSelected(option) {
        if (option.oneTimeCost > 0) {
          this.get('money').subtractFunds(option.oneTimeCost, option.header);
          var currentGame = this.get('gameData.currentGame');
          currentGame.get('purchasedSiteItems').addObject(option.key);
          currentGame.save();
        }
      },
      domainAndHostingOptionSelected: function domainAndHostingOptionSelected(option) {
        if (option.monthlyCosts > 0) {
          this.get('money').addRecurringExpense('monthly', option.monthlyCosts, this.get('i18n').t('create_a_site.monthly_hosting_fee'), true);
          this.get('money').addRecurringExpense('yearly', option.yearlyCosts, this.get('i18n').t('create_a_site.domain_registration_for', { "siteName": this.get('model.name') }), true);

          var currentGame = this.get('gameData.currentGame');
          currentGame.set('hasHosting', true);
          currentGame.get('purchasedSiteItems').addObject(option.key);
          currentGame.save();
        }
      },
      openMoveModal: function openMoveModal(article) {
        this.set('gameData.gamePaused', true);
        this.set('gameData.articleToMove', article);
        var self = this;
        $('.ui.move.modal').modal({
          onHidden: function onHidden() {
            self.set('gameData.gamePaused', false);
          }
        }).modal('show');
      },
      deleteArticle: function deleteArticle(article) {
        article.destroyRecord();
      },
      closePopup: function closePopup() {
        $('.deletePopUp').popup('hide');
      }
    }
  });
});
define('fakenews/components/political-slant-label', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    color: _ember['default'].computed('politicalSlant', function () {
      var politicalSlant = parseInt(this.get('politicalSlant'));
      var color = "grey";
      if (politicalSlant === 1) {
        color = "orange";
      } else if (politicalSlant === 2) {
        color = "purple";
      }
      return color;
    })
  });
});
define('fakenews/models/user-article', ['exports', 'ember-data', 'ember', 'fakenews/mixins/article'], function (exports, _emberData, _ember, _fakenewsMixinsArticle) {
  exports['default'] = _emberData['default'].Model.extend(_fakenewsMixinsArticle['default'], {
    copiedFrom: _emberData['default'].belongsTo('article'),
    site: _emberData['default'].belongsTo('site', { autoSave: true }),

    userPlants: _emberData['default'].hasMany('user-plant', { dependent: 'destroy' }),
    lastTouchDate: _emberData['default'].attr('date'),
    groupsPlantedTo: _ember['default'].computed.mapBy('userPlants', 'plantedTo'),

    debunked: _emberData['default'].attr('boolean', { defaultValue: false }),
    famousShare: _emberData['default'].attr('boolean', { defaultValue: false }),

    famousShareBonus: _emberData['default'].attr('number', { defaultValue: 0 }),
    couldGoViral: _ember['default'].computed('drama', 'believability', 'shares', 'politicalSlant', function () {
      var couldGoViral = false;
      var isPolitical = this.get('politicalSlant') === 1 || this.get('politicalSlant') === 2;
      if (this.get('believability') > 14 && this.get('drama') > 14 && isPolitical && this.get('shares') > 50) {
        couldGoViral = true;
      }
      return couldGoViral;
    }),

    aboutDangerousPeople: _ember['default'].computed('tags', function () {
      return this.matchesTag("17") || this.matchesTag("18") || this.matchesTag("114") || this.matchesTag("113");
    }),

    aboutHealthDangers: _ember['default'].computed('tags', function () {
      return this.matchesTag("97");
    }),

    aboutCrimeIncreasing: _ember['default'].computed('tags', function () {
      return this.matchesTag("115");
    }),

    potentialToGetFired: _ember['default'].computed('tags', function () {
      return this.matchesTag("3") || this.matchesTag("4") || this.matchesTag("131") || this.matchesTag("95") || this.matchesTag("94") || this.matchesTag("93") || this.matchesTag("96") || this.matchesTag("81") || this.matchesTag("82");
    }),

    matchesTag: function matchesTag(targetId) {
      var tags = this.get('tags');
      var hasMatch = false;

      if (tags.get('length') > 0) {
        var matching = tags.filterBy('id', targetId);
        if (matching.get('length') > 0) {
          hasMatch = true;
        }
      }
      return hasMatch;
    },

    views: _emberData['default'].attr('number', { defaultValue: 0 }),
    likes: _emberData['default'].attr('number', { defaultValue: 0 }),
    shares: _emberData['default'].attr('number', { defaultValue: 0 }),
    revenue: _emberData['default'].attr('number', { defaultValue: 0 }),

    recentShares: _emberData['default'].attr('number', { defaultValue: 0 }),

    saved: ('boolean', { defaultValue: false })
  });
});
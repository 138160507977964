define('fakenews/routes/play/site/create', ['exports', 'ember', 'rsvp'], function (exports, _ember, _rsvp) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return _rsvp['default'].hash({
        site: this.modelFor('play.site'),
        siteNames1: _ember['default'].$.getJSON('../../../data/siteNames1.json'),
        siteNames2: _ember['default'].$.getJSON('../../../data/siteNames2.json'),
        logoOptions: _ember['default'].$.getJSON('../../../data/logoOptions.json')
      });
    }
  });
});
define('fakenews/mixins/pause-page', ['exports', 'ember', 'ember-concurrency'], function (exports, _ember, _emberConcurrency) {
  exports['default'] = _ember['default'].Mixin.create({
    didReceiveAttrs: function didReceiveAttrs() {
      this.set('gameData.gamePaused', true);
      this.get('checkPause').perform();
    },

    checkPause: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0() {
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return (0, _emberConcurrency.timeout)(1000);

          case 2:
            this.set('gameData.gamePaused', true);

          case 3:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    willDestroyElement: function willDestroyElement() {
      this.set('gameData.gamePaused', false);
    }
  });
});
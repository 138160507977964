define('fakenews/routes/play/sites', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    afterModel: function afterModel() {
      if (_ember['default'].isBlank(this.get('gameData.currentGame.gameGoal'))) {
        this.transitionTo('index');
      } else {
        this.set('gameData.currentSite', null);
      }
    }
  });
});
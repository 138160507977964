define("fakenews/templates/components/plant-selections", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 4
              },
              "end": {
                "line": 21,
                "column": 4
              }
            },
            "moduleName": "fakenews/templates/components/plant-selections.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "ui blue message");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", ["plant_an_article.article.none"], [], ["loc", [null, [19, 8], [19, 45]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 40,
                    "column": 10
                  },
                  "end": {
                    "line": 40,
                    "column": 217
                  }
                },
                "moduleName": "fakenews/templates/components/plant-selections.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode(" ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "ui teal label");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(dom.childAt(fragment, [2]), 0, 0);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["inline", "t", ["plant_an_article.profile.select_profile"], [], ["loc", [null, [40, 102], [40, 149]]], 0, 0], ["content", "userProfilesToDisplay.length", ["loc", [null, [40, 178], [40, 210]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 41,
                    "column": 10
                  },
                  "end": {
                    "line": 41,
                    "column": 147
                  }
                },
                "moduleName": "fakenews/templates/components/plant-selections.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "t", ["plant_an_article.profile.buy_profiles"], [], ["loc", [null, [41, 102], [41, 147]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 42,
                    "column": 10
                  },
                  "end": {
                    "line": 42,
                    "column": 155
                  }
                },
                "moduleName": "fakenews/templates/components/plant-selections.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "t", ["plant_an_article.profile.create_a_new_profile"], [], ["loc", [null, [42, 102], [42, 155]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 6
                },
                "end": {
                  "line": 44,
                  "column": 6
                }
              },
              "moduleName": "fakenews/templates/components/plant-selections.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "ui top attached pointing teal three item menu");
              dom.setAttribute(el1, "role", "presentation");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element3, 1, 1);
              morphs[1] = dom.createMorphAt(element3, 3, 3);
              morphs[2] = dom.createMorphAt(element3, 5, 5);
              return morphs;
            },
            statements: [["block", "tablist.tab", ["ProfilesTabA"], ["on-select", ["subexpr", "action", [["subexpr", "mut", [["get", "profileTabSelection", ["loc", [null, [40, 63], [40, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [40, 58], [40, 83]]], 0, 0]], [], ["loc", [null, [40, 50], [40, 84]]], 0, 0], "class", "ui item"], 0, null, ["loc", [null, [40, 10], [40, 233]]]], ["block", "tablist.tab", ["ProfilesTabC"], ["on-select", ["subexpr", "action", [["subexpr", "mut", [["get", "profileTabSelection", ["loc", [null, [41, 63], [41, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [41, 58], [41, 83]]], 0, 0]], [], ["loc", [null, [41, 50], [41, 84]]], 0, 0], "class", "ui item"], 1, null, ["loc", [null, [41, 10], [41, 163]]]], ["block", "tablist.tab", ["ProfilesTabB"], ["on-select", ["subexpr", "action", [["subexpr", "mut", [["get", "profileTabSelection", ["loc", [null, [42, 63], [42, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [42, 58], [42, 83]]], 0, 0]], [], ["loc", [null, [42, 50], [42, 84]]], 0, 0], "class", "ui item"], 2, null, ["loc", [null, [42, 10], [42, 171]]]]],
            locals: ["tablist"],
            templates: [child0, child1, child2]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 46,
                    "column": 8
                  },
                  "end": {
                    "line": 50,
                    "column": 8
                  }
                },
                "moduleName": "fakenews/templates/components/plant-selections.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "ui blue message");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["inline", "t", ["plant_an_article.profile.none"], [], ["loc", [null, [48, 12], [48, 49]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 6
                },
                "end": {
                  "line": 60,
                  "column": 6
                }
              },
              "moduleName": "fakenews/templates/components/plant-selections.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["block", "unless", [["get", "hasUserProfiles", ["loc", [null, [46, 18], [46, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [46, 8], [50, 19]]]], ["inline", "standard-filters-display", [], ["items", ["subexpr", "@mut", [["get", "userProfilesToDisplay", ["loc", [null, [52, 17], [52, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "itemSelected", ["subexpr", "action", ["selectProfile"], [], ["loc", [null, [53, 25], [53, 49]]], 0, 0], "shortVersion", false, "showSort", false, "componentName", "profile-summary", "showSelectedRibbon", true], ["loc", [null, [51, 8], [58, 10]]], 0, 0]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 62,
                  "column": 6
                },
                "end": {
                  "line": 67,
                  "column": 6
                }
              },
              "moduleName": "fakenews/templates/components/plant-selections.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "buy-profiles", [], ["profiles", ["subexpr", "@mut", [["get", "uncopiedProfiles", ["loc", [null, [64, 21], [64, 37]]], 0, 0, 0, 0]], [], [], 0, 0], "selectProfile", ["subexpr", "action", ["selectProfile"], [], ["loc", [null, [65, 26], [65, 50]]], 0, 0]], ["loc", [null, [63, 10], [66, 12]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 69,
                  "column": 6
                },
                "end": {
                  "line": 73,
                  "column": 6
                }
              },
              "moduleName": "fakenews/templates/components/plant-selections.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "create-profile", [], ["selectProfile", ["subexpr", "action", ["selectProfile"], [], ["loc", [null, [71, 24], [71, 48]]], 0, 0]], ["loc", [null, [70, 8], [72, 10]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 4
              },
              "end": {
                "line": 74,
                "column": 4
              }
            },
            "moduleName": "fakenews/templates/components/plant-selections.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "tabs.tablist", [], [], 0, null, ["loc", [null, [38, 6], [44, 23]]]], ["block", "tabs.tabpanel", ["ProfilesTabA"], ["class", "ui bottom attached tab segment"], 1, null, ["loc", [null, [45, 6], [60, 24]]]], ["block", "tabs.tabpanel", ["ProfilesTabC"], ["class", "ui bottom attached tab segment"], 2, null, ["loc", [null, [62, 6], [67, 24]]]], ["block", "tabs.tabpanel", ["ProfilesTabB"], ["class", "ui bottom attached tab segment"], 3, null, ["loc", [null, [69, 6], [73, 24]]]]],
          locals: ["tabs"],
          templates: [child0, child1, child2, child3]
        };
      })();
      var child2 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 85,
                      "column": 12
                    },
                    "end": {
                      "line": 88,
                      "column": 12
                    }
                  },
                  "moduleName": "fakenews/templates/components/plant-selections.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "ui teal label");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
                  return morphs;
                },
                statements: [["inline", "t", ["plant_an_article.group.select_group"], ["profileName", ["subexpr", "@mut", [["get", "gameData.currentProfile.name", ["loc", [null, [86, 68], [86, 96]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [86, 14], [86, 98]]], 0, 0], ["content", "gameData.currentProfile.groups.length", ["loc", [null, [87, 42], [87, 83]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 89,
                      "column": 12
                    },
                    "end": {
                      "line": 91,
                      "column": 12
                    }
                  },
                  "moduleName": "fakenews/templates/components/plant-selections.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "t", ["plant_an_article.group.join_groups"], [], ["loc", [null, [90, 14], [90, 56]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 83,
                    "column": 8
                  },
                  "end": {
                    "line": 93,
                    "column": 8
                  }
                },
                "moduleName": "fakenews/templates/components/plant-selections.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "ui top attached pointing teal two item menu");
                dom.setAttribute(el1, "role", "presentation");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element2, 1, 1);
                morphs[1] = dom.createMorphAt(element2, 2, 2);
                return morphs;
              },
              statements: [["block", "tablist.tab", ["GroupsTabA"], ["on-select", ["subexpr", "action", [["subexpr", "mut", [["get", "groupTabSelection", ["loc", [null, [85, 63], [85, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [85, 58], [85, 81]]], 0, 0]], [], ["loc", [null, [85, 50], [85, 82]]], 0, 0], "class", "ui item"], 0, null, ["loc", [null, [85, 12], [88, 28]]]], ["block", "tablist.tab", ["GroupsTabB"], ["on-select", ["subexpr", "action", [["subexpr", "mut", [["get", "groupTabSelection", ["loc", [null, [89, 63], [89, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [89, 58], [89, 81]]], 0, 0]], [], ["loc", [null, [89, 50], [89, 82]]], 0, 0], "class", "ui item"], 1, null, ["loc", [null, [89, 12], [91, 28]]]]],
              locals: ["tablist"],
              templates: [child0, child1]
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.8.3",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 96,
                      "column": 12
                    },
                    "end": {
                      "line": 100,
                      "column": 12
                    }
                  },
                  "moduleName": "fakenews/templates/components/plant-selections.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "ui blue message");
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n              ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["inline", "t", ["plant_an_article.group.none"], [], ["loc", [null, [98, 16], [98, 51]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 95,
                    "column": 8
                  },
                  "end": {
                    "line": 110,
                    "column": 8
                  }
                },
                "moduleName": "fakenews/templates/components/plant-selections.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "unless", [["get", "profileHasGroups", ["loc", [null, [96, 22], [96, 38]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [96, 12], [100, 23]]]], ["inline", "standard-filters-display", [], ["items", ["subexpr", "@mut", [["get", "gameData.currentProfile.sortedByPlantedGroups", ["loc", [null, [102, 23], [102, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "itemSelected", ["subexpr", "action", ["selectGroup"], [], ["loc", [null, [103, 31], [103, 53]]], 0, 0], "shortVersion", false, "showSort", false, "actionEnabled", true, "componentName", "group-summary", "showSelectedRibbon", true], ["loc", [null, [101, 14], [109, 16]]], 0, 0]],
              locals: [],
              templates: [child0]
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@2.8.3",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 112,
                    "column": 8
                  },
                  "end": {
                    "line": 117,
                    "column": 8
                  }
                },
                "moduleName": "fakenews/templates/components/plant-selections.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "join-groups", [], ["groups", ["subexpr", "@mut", [["get", "unjoinedGroups", ["loc", [null, [114, 21], [114, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "itemSelected", ["subexpr", "action", ["selectGroup"], [], ["loc", [null, [115, 29], [115, 51]]], 0, 0]], ["loc", [null, [113, 12], [116, 14]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 82,
                  "column": 6
                },
                "end": {
                  "line": 119,
                  "column": 6
                }
              },
              "moduleName": "fakenews/templates/components/plant-selections.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["block", "tabs.tablist", [], [], 0, null, ["loc", [null, [83, 8], [93, 25]]]], ["block", "tabs.tabpanel", ["GroupsTabA"], ["class", "ui bottom attached tab segment"], 1, null, ["loc", [null, [95, 8], [110, 26]]]], ["block", "tabs.tabpanel", ["GroupsTabB"], ["class", "ui bottom attached tab segment"], 2, null, ["loc", [null, [112, 8], [117, 26]]]]],
            locals: ["tabs"],
            templates: [child0, child1, child2]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 81,
                "column": 4
              },
              "end": {
                "line": 120,
                "column": 4
              }
            },
            "moduleName": "fakenews/templates/components/plant-selections.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "ivy-tabs", [], ["selection", ["subexpr", "@mut", [["get", "groupTabSelection", ["loc", [null, [82, 28], [82, 45]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [82, 6], [119, 19]]]]],
          locals: [],
          templates: [child0]
        };
      })();
      var child3 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 120,
                "column": 4
              },
              "end": {
                "line": 124,
                "column": 4
              }
            },
            "moduleName": "fakenews/templates/components/plant-selections.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "ui blue message");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["plant_an_article.group.no_profile_selected"], [], ["loc", [null, [122, 11], [122, 61]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child4 = (function () {
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 131,
                "column": 4
              },
              "end": {
                "line": 133,
                "column": 4
              }
            },
            "moduleName": "fakenews/templates/components/plant-selections.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("p");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["plant_an_article.review.ready_to_submit"], [], ["loc", [null, [132, 9], [132, 56]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child5 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 137,
                  "column": 8
                },
                "end": {
                  "line": 137,
                  "column": 99
                }
              },
              "moduleName": "fakenews/templates/components/plant-selections.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("li");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["plant_an_article.review.missing.article"], [], ["loc", [null, [137, 47], [137, 94]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 138,
                  "column": 8
                },
                "end": {
                  "line": 138,
                  "column": 99
                }
              },
              "moduleName": "fakenews/templates/components/plant-selections.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("li");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["plant_an_article.review.missing.profile"], [], ["loc", [null, [138, 47], [138, 94]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.8.3",
              "loc": {
                "source": null,
                "start": {
                  "line": 139,
                  "column": 8
                },
                "end": {
                  "line": 139,
                  "column": 95
                }
              },
              "moduleName": "fakenews/templates/components/plant-selections.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createElement("li");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["plant_an_article.review.missing.group"], [], ["loc", [null, [139, 45], [139, 90]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.8.3",
            "loc": {
              "source": null,
              "start": {
                "line": 133,
                "column": 4
              },
              "end": {
                "line": 142,
                "column": 4
              }
            },
            "moduleName": "fakenews/templates/components/plant-selections.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "ui blue message");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("ul");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var element1 = dom.childAt(element0, [3]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(element1, 1, 1);
            morphs[2] = dom.createMorphAt(element1, 3, 3);
            morphs[3] = dom.createMorphAt(element1, 5, 5);
            return morphs;
          },
          statements: [["inline", "t", ["plant_an_article.review.missing.overall"], [], ["loc", [null, [135, 9], [135, 56]]], 0, 0], ["block", "unless", [["get", "gameData.currentArticle", ["loc", [null, [137, 18], [137, 41]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [137, 8], [137, 110]]]], ["block", "unless", [["get", "gameData.currentProfile", ["loc", [null, [138, 18], [138, 41]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [138, 8], [138, 110]]]], ["block", "unless", [["get", "gameData.currentGroup", ["loc", [null, [139, 18], [139, 39]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [139, 8], [139, 106]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.8.3",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 0
            },
            "end": {
              "line": 144,
              "column": 0
            }
          },
          "moduleName": "fakenews/templates/components/plant-selections.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "active title");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "dropdown icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "active content");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "title");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "dropdown icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "content");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "title");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "dropdown icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "content");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "title");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "dropdown icon");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "content");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [3]);
          var morphs = new Array(9);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
          morphs[1] = dom.createMorphAt(element4, 1, 1);
          morphs[2] = dom.createMorphAt(element4, 3, 3);
          morphs[3] = dom.createMorphAt(dom.childAt(fragment, [5]), 3, 3);
          morphs[4] = dom.createMorphAt(dom.childAt(fragment, [7]), 1, 1);
          morphs[5] = dom.createMorphAt(dom.childAt(fragment, [9]), 3, 3);
          morphs[6] = dom.createMorphAt(dom.childAt(fragment, [11]), 1, 1);
          morphs[7] = dom.createMorphAt(dom.childAt(fragment, [13]), 3, 3);
          morphs[8] = dom.createMorphAt(dom.childAt(fragment, [15]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["plant_an_article.article.instructions"], ["siteName", ["subexpr", "@mut", [["get", "site.name", ["loc", [null, [13, 57], [13, 66]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 4], [13, 68]]], 0, 0], ["block", "unless", [["get", "articlesToDisplay", ["loc", [null, [17, 14], [17, 31]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [17, 4], [21, 15]]]], ["inline", "standard-filters-display", [], ["items", ["subexpr", "@mut", [["get", "articlesToDisplay", ["loc", [null, [23, 13], [23, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "itemSelected", ["subexpr", "action", ["selectArticle"], [], ["loc", [null, [24, 21], [24, 45]]], 0, 0], "shortVersion", false, "showSort", true, "componentName", "article-summary", "showSelectedRibbon", true], ["loc", [null, [22, 4], [29, 6]]], 0, 0], ["inline", "t", ["plant_an_article.profile.instructions"], [], ["loc", [null, [33, 4], [33, 49]]], 0, 0], ["block", "ivy-tabs", [], ["selection", ["subexpr", "@mut", [["get", "profileTabSelection", ["loc", [null, [37, 26], [37, 45]]], 0, 0, 0, 0]], [], [], 0, 0]], 1, null, ["loc", [null, [37, 4], [74, 17]]]], ["inline", "t", ["plant_an_article.group.instructions"], [], ["loc", [null, [78, 4], [78, 47]]], 0, 0], ["block", "if", [["get", "hasCurrentProfile", ["loc", [null, [81, 10], [81, 27]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [81, 4], [124, 11]]]], ["inline", "t", ["plant_an_article.review.instructions"], [], ["loc", [null, [128, 4], [128, 48]]], 0, 0], ["block", "if", [["get", "hasArticleProfileGroup", ["loc", [null, [131, 10], [131, 32]]], 0, 0, 0, 0]], [], 4, 5, ["loc", [null, [131, 4], [142, 11]]]]],
        locals: ["execute"],
        templates: [child0, child1, child2, child3, child4, child5]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 146,
            "column": 0
          }
        },
        "moduleName": "fakenews/templates/components/plant-selections.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "ui raised segment");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "ui right floated circle large teal help icon");
        dom.setAttribute(el2, "style", "touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;");
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h3");
        dom.setAttribute(el2, "class", "ui left floated header");
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "share alternate icon");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "ui clearing divider");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [1]);
        var element6 = dom.childAt(element5, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element5, 1, 1);
        morphs[1] = dom.createElementMorph(element6);
        morphs[2] = dom.createElementMorph(element6);
        morphs[3] = dom.createMorphAt(dom.childAt(element5, [5]), 3, 3);
        morphs[4] = dom.createMorphAt(element5, 9, 9);
        return morphs;
      },
      statements: [["inline", "ui-popup", [], ["class", "helpup"], ["loc", [null, [3, 2], [3, 29]]], 0, 0], ["element", "action", ["openHelp"], [], ["loc", [null, [4, 58], [4, 79]]], 0, 0], ["element", "action", ["openHelp"], ["on", "touchEnd"], ["loc", [null, [4, 79], [4, 114]]], 0, 0], ["inline", "t", ["plant_an_article.header"], [], ["loc", [null, [7, 39], [7, 70]]], 0, 0], ["block", "ui-accordion", [], ["class", "fluid", "onChange", ["subexpr", "action", ["accordionSelected"], [], ["loc", [null, [10, 39], [10, 67]]], 0, 0]], 0, null, ["loc", [null, [10, 0], [144, 17]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});
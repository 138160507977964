define('fakenews/routes/play/initial', ['exports', 'ember', 'rsvp'], function (exports, _ember, _rsvp) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return _rsvp['default'].hash({
        goals: _ember['default'].$.getJSON('../../../data/goals.json'),
        guides: [{
          imgSrc: "cousin_1_head.png",
          fullImgSrc: "cousin_1.png"

        }, {
          imgSrc: "cousin_2_head.png",
          fullImgSrc: "cousin_2.png"

        }, {
          imgSrc: "cousin_3_head.png",
          fullImgSrc: "cousin_3.png"

        }, {
          imgSrc: "cousin_4_head.png",
          fullImgSrc: "cousin_4.png"

        }]
      });
    },
    afterModel: function afterModel() {
      this.set('gameData.currentSite', null);
    }
  });
});
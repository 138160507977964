define('fakenews/components/move-article-modal', ['exports', 'semantic-ui-ember/components/ui-modal', 'ember'], function (exports, _semanticUiEmberComponentsUiModal, _ember) {
  exports['default'] = _semanticUiEmberComponentsUiModal['default'].extend({
    name: 'move',
    classNames: ['move'],

    currentSite: _ember['default'].computed.reads('gameData.articleToMove.site'),

    allSites: _ember['default'].computed.reads('gameData.currentGame.savedSites'),

    sitesExceptCurrent: _ember['default'].computed('allSites', 'currentSite', function () {
      var sites = this.get('allSites');
      if (_ember['default'].isPresent(sites)) {
        sites = sites.without(this.get('currentSite.content'));
      }
      return sites;
    }),

    actions: {
      closeModal: function closeModal() {
        $('.ui.move.modal').modal('hide');
      },
      moveArticleToSite: function moveArticleToSite(newSite) {
        var article = this.get('gameData.articleToMove');

        var previousSite = article.get('site.content');

        previousSite.get('articles').removeObject(article);
        newSite.get('articles').addObject(article);

        _ember['default'].set(article, 'site', newSite);

        _ember['default'].set(article, 'views', 0);
        _ember['default'].set(article, 'likes', 0);
        _ember['default'].set(article, 'shares', 0);
        _ember['default'].set(article, 'revenue', 0);

        _ember['default'].set(article, 'recentShares', 0);

        article.get('userPlants').forEach(function (plant) {
          _ember['default'].set(plant, 'active', false);
        });

        article.save();
        previousSite.save();
        newSite.save();

        this.send('closeModal');
      }
    }

  });
});
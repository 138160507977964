define('fakenews/mixins/has-time-passing-modal', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    openTimePassingModal: function openTimePassingModal(title, text) {
      this.set('gameData.timePassingModalTitle', title);
      this.set('gameData.timePassingModalText', text);
      $('.ui.time-passing.modal').modal('setting', 'closable', false).modal('show');
    },
    closeTimePassingModal: function closeTimePassingModal() {
      $('.ui.time-passing.modal').modal('hide');
    }
  });
});
define('fakenews/components/sortable-supports', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    includedSupports: _ember['default'].computed('supports.@each.hasTags', 'supports.@each.tags', 'selectedBaseTags', function () {
      var includedSupports = this.get('supports').filterBy('matching', false);

      var selectedBaseTags = this.get('selectedBaseTags');
      var supportsWithTags = this.get('supports').filterBy('matching');
      supportsWithTags.forEach(function (support) {
        selectedBaseTags.forEach(function (tag) {
          var tagSubjects = support.get('tags').mapBy('subject');
          if (tagSubjects.includes(tag.get('subject'))) {
            includedSupports.addObject(support);
          }
        });
      });

      return includedSupports;
    }),
    filteredSupports: _ember['default'].computed('includedSupports', 'selectedSupportFilter', function () {
      var includedSupports = this.get('includedSupports');
      includedSupports = includedSupports.filterBy(this.get('selectedSupportFilter')).reverse();
      return includedSupports;
    }),
    selectedSupportFilter: 'drama'

  });
});
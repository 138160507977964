define('fakenews/services/planter', ['exports', 'ember', 'ember-concurrency', 'fakenews/mixins/retrieve-nested'], function (exports, _ember, _emberConcurrency, _fakenewsMixinsRetrieveNested) {
  exports['default'] = _ember['default'].Service.extend(_fakenewsMixinsRetrieveNested['default'], {
    store: _ember['default'].inject.service(),
    gameData: _ember['default'].inject.service(),

    done: false,

    // When you generate comments, don't just base on interest for the number
    // Interest - coment, like, view, share, etc. -- these are all a little differet
    // Hook up the emotions and other reports again

    plantArticle: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(article, profile, group) {
      var site, plantData, newPlant, emotions, comments, commentBubbles;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.set('done', false);
            _ember['default'].set(article, 'selected', false);
            _ember['default'].set(profile, 'selected', false);
            _ember['default'].set(group, 'selected', false);

            site = article.get('site');
            plantData = {
              article: article,
              plantedBy: profile,
              plantedTo: group,
              site: site,
              hoursSincePlanted: 0,
              createdAt: this.get('gameData.currentGame.date')
            };
            newPlant = this.get('store').createRecord('user-plant', plantData);

            if (article.get('politicalSlant') === 0 || article.get('politicalSlant') === 3) {
              newPlant.set('isNeutralArticle', true);
            } else if (group.get('politicalSlant') === 0 || group.get('politicalSlant') === 3) {
              newPlant.set('isNeutralGroup', true);
            } else if (group.get('politicalSlant') === article.get('politicalSlant')) {
              newPlant.set('isPoliticalMatch', true);
            } else {
              newPlant.set('isPoliticalMismatch', true);
            }

            if (this.get('gameData.currentGame.trendingTopicsEnabled')) {
              newPlant.set('trendingTopicMatches', this.matchingTags(article.get('tags'), this.get('gameData.currentGame.trendingTags')));
            }
            newPlant.set('groupTopicMatches', this.matchingSubjects(article.get('tags'), group.get('tags')));

            newPlant.set('interestLevel', this.generateInterestLevel(newPlant));
            newPlant.set('feedback', this.generateFeedback(newPlant.get('interestLevel')));

            context$1$0.next = 14;
            return this.get('generateEmotions').perform(newPlant);

          case 14:
            emotions = context$1$0.sent;

            newPlant.set('emotions', emotions);
            context$1$0.next = 18;
            return this.get('generateComments').perform(newPlant);

          case 18:
            comments = context$1$0.sent;
            context$1$0.next = 21;
            return this.generateCommentBubbles(comments);

          case 21:
            commentBubbles = context$1$0.sent;

            newPlant.set('comments', comments);
            newPlant.set('commentBubbles', commentBubbles);

            context$1$0.next = 26;
            return article.set('lastTouchDate', this.get('gameData.currentGame.date'));

          case 26:
            article.save();
            profile.save();
            site.get('content').save();

            newPlant.save();

            //this.get('initialShare').perform(newPlant);

            return context$1$0.abrupt('return', newPlant);

          case 31:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    i18n: _ember['default'].inject.service(),

    generateInterestLevel: function generateInterestLevel(plant) {
      var interestLevel = "none";
      if (plant.get('isPoliticalMismatch')) {
        interestLevel = "hostile";
      } else if (plant.get('isNeutralGroup') || plant.get('isNeutralArticle')) {
        if (plant.get('isGroupTopicMatch') && plant.get('hasHighBelievability') && plant.get('hasHighDrama') && !plant.get('hasLowSiteCredibility')) {
          interestLevel = "moderate";
        } else if (plant.get('isGroupTopicMatch')) {
          interestLevel = "mild";
        }
      } else {
        if (plant.get('hasHighBelievability') && plant.get('hasHighDrama') && plant.get('wasTrendingTopic') && !plant.get('hasLowSiteCredibility')) {
          interestLevel = "viral";
        } else if (plant.get('hasModerateDrama') && plant.get('hasModerateBelievability')) {
          interestLevel = "good";
        } else if (plant.get('hasModerateDrama')) {
          interestLevel = "moderate";
        } else {
          interestLevel = "mild";
        }
      }

      return interestLevel;
    },

    generateFeedback: function generateFeedback(interestLevel) {
      var feedbackKey = "plant_an_article.feedback.interest_level." + interestLevel;
      var feedback = this.get('i18n').t(feedbackKey);

      return feedback;
    },

    generateEmotions: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(newPlant) {
      var emotions, hostileEmotion, skepticalEmotion;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            emotions = [];

            if (!newPlant.get('isPoliticalMismatch')) {
              context$1$0.next = 8;
              break;
            }

            context$1$0.next = 4;
            return this.get('store').findRecord('emotion', 15);

          case 4:
            hostileEmotion = context$1$0.sent;

            emotions.addObject(hostileEmotion);
            context$1$0.next = 17;
            break;

          case 8:
            if (!(newPlant.get('interestLevel') !== "none")) {
              context$1$0.next = 12;
              break;
            }

            context$1$0.next = 11;
            return this.get('retrieveNestedTask').perform(newPlant.get('article.tags'), 'emotions');

          case 11:
            emotions = context$1$0.sent;

          case 12:
            if (!(!newPlant.get('hasModerateBelievability') || newPlant.get('article.debunked') || newPlant.get('hasLowSiteCredibility'))) {
              context$1$0.next = 17;
              break;
            }

            context$1$0.next = 15;
            return this.get('store').findRecord('emotion', 16);

          case 15:
            skepticalEmotion = context$1$0.sent;

            emotions.addObject(skepticalEmotion);

          case 17:
            return context$1$0.abrupt('return', emotions);

          case 18:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    displayedCommentBubbles: [],
    images: ['person_1.png', 'person_2.png', 'person_3.png', 'person_4.png', 'person_5.png', 'person_6.png', 'person_7.png', 'person_8.png', 'person_9.png', 'person_10.png', 'person_11.png', 'person_12.png', 'person_13.png', 'person_14.png', 'person_15.png'],

    displayCommentBubbles: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(plant) {
      var likeFactor, shareFactor, viewFactor, commentBubbles, numberOfCommentBubbles, i, newLikes, newShares, newViews, newRevenue;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            this.setProperties({
              displayedCommentBubbles: []
            });

            likeFactor = plant.get('likeFactor');
            shareFactor = plant.get('shareFactor');
            viewFactor = plant.get('viewFactor');
            commentBubbles = plant.get('commentBubbles');
            numberOfCommentBubbles = commentBubbles.get('length');
            i = 0;

          case 7:
            if (!(i < numberOfCommentBubbles)) {
              context$1$0.next = 22;
              break;
            }

            context$1$0.next = 10;
            return (0, _emberConcurrency.timeout)(10000 * Math.random() / (i + 1));

          case 10:
            this.get('displayedCommentBubbles').addObject(commentBubbles.objectAt(i));

            newLikes = Math.floor(Math.random() * likeFactor);
            newShares = Math.floor(Math.random() * shareFactor);
            newViews = Math.floor(viewFactor * Math.random());
            newRevenue = Math.floor(newViews * Math.random()) / 100;

            this.updatePlantProperty(plant, 'likes', newLikes);
            this.updatePlantProperty(plant, 'shares', newShares);
            this.updatePlantProperty(plant, 'views', newViews);
            this.updatePlantProperty(plant, 'revenue', newRevenue);
            i++;
            context$1$0.next = 7;
            break;

          case 22:
            context$1$0.next = 24;
            return (0, _emberConcurrency.timeout)(1000);

          case 24:
            context$1$0.next = 26;
            return (0, _emberConcurrency.timeout)(1000 * (8 - numberOfCommentBubbles));

          case 26:
            this.set('done', true);

            if (likeFactor > 0) {
              plant.set('active', true);
              plant.save();
            }

          case 28:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    randomIndexes: function randomIndexes(totalNumber, maxNumber) {
      var arr = [];
      if (totalNumber > maxNumber) {
        totalNumber = maxNumber;
      }
      while (arr.length < totalNumber) {
        var newNumber = Math.floor(Math.random() * maxNumber);
        if (arr.indexOf(newNumber) > -1) {
          continue;
        }
        arr[arr.length] = newNumber;
      }
      return arr;
    },

    // Eventualy - whether or not stuff shows up at al should be based on plant (and that text should be too, but this will all be much more complicated)
    generateComments: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(newPlant) {
      var possibleComments, uniquePossibleComments, numberOfComments, commentIndexes, comments, i;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.get('retrieveNestedTask').perform(newPlant.get('emotions'), 'comments');

          case 2:
            possibleComments = context$1$0.sent;
            context$1$0.next = 5;
            return possibleComments.uniq();

          case 5:
            uniquePossibleComments = context$1$0.sent;
            numberOfComments = newPlant.get('numberOfComments');

            if (numberOfComments > uniquePossibleComments.get('length')) {
              numberOfComments = uniquePossibleComments.get('length');
            }

            commentIndexes = this.randomIndexes(numberOfComments, uniquePossibleComments.get('length'));
            comments = [];
            i = 0;

            while (i < numberOfComments) {
              comments.push(possibleComments.objectAt(commentIndexes[i]));
              i++;
            }

            return context$1$0.abrupt('return', comments);

          case 13:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),

    generateCommentBubbles: function generateCommentBubbles(comments) {
      var i = 0;
      var commentBubbles = [];
      var numberOfBubbles = comments.get('length');

      var imageIndexes = this.randomIndexes(numberOfBubbles, this.get('images.length'));

      while (i < numberOfBubbles) {
        commentBubbles.push({
          id: "bubble" + i,
          image: this.get('images').objectAt(imageIndexes[i]),
          text: comments.objectAt(i).get('text')
        });
        i++;
      }

      return commentBubbles;
    },

    matchingTags: function matchingTags(item1Tags, item2Tags) {
      var tags = [];
      for (var i = 0; i < item1Tags.get('length'); i++) {
        for (var j = 0; j < item2Tags.get('length'); j++) {
          if (item1Tags.objectAt(i).get('id') === item2Tags.objectAt(j).get('id')) {
            tags.push(item1Tags.objectAt(i));
          }
        }
      }
      return tags;
    },

    matchingSubjects: function matchingSubjects(item1Tags, item2Tags) {
      var tags = [];
      for (var i = 0; i < item1Tags.get('length'); i++) {
        for (var j = 0; j < item2Tags.get('length'); j++) {
          if (item1Tags.objectAt(i).get('subject') === item2Tags.objectAt(j).get('subject')) {
            tags.push(item1Tags.objectAt(i));
          }
        }
      }
      return tags;
    },

    updatePlantProperty: function updatePlantProperty(plant, property, change) {

      if (change >= 0) {
        plant.incrementProperty(property, change);
        plant.save();

        var article = plant.get('article.content');

        this.updateArticleProperty(article, property, change);
      }
    },
    updateArticleProperty: function updateArticleProperty(article, property, change) {

      if (change >= 0) {

        article.incrementProperty(property, change);

        var site = article.get('site.content');
        site.incrementProperty(property, change);

        var game = this.get('gameData.currentGame');
        game.incrementProperty(property, change);

        article.save();
        site.save();
        //game.save();
      }
    },
    updateActivePlants: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function callee$0$0(hideSpecialEvents) {
      var articles, plants, i, x, plant, group, groupMembers, hoursSincePlanted, groupMemberBonus, timeDrag, likeFactor, shareFactor, viewFactor, newLikes, newShares, newViews, newRevenue, article;
      return regeneratorRuntime.wrap(function callee$0$0$(context$1$0) {
        while (1) switch (context$1$0.prev = context$1$0.next) {
          case 0:
            context$1$0.next = 2;
            return this.get('gameData.currentGame.articles');

          case 2:
            articles = context$1$0.sent;
            context$1$0.next = 5;
            return this.get('gameData.currentGame.activePlants');

          case 5:
            plants = context$1$0.sent;

            articles.forEach(function (article) {
              var recentShares = article.get('recentShares');
              var totalShares = article.get('shares');

              if (recentShares > 0) {
                var trendingTopicMatches = [];
                if (this.get('gameData.currentGame.trendingTopicsEnabled')) {
                  trendingTopicMatches = this.matchingTags(article.get('tags'), this.get('gameData.currentGame.trendingTags'));
                }
                var debunked = article.get('debunked');
                var famousShareBonus = article.get('famousShareBonus');

                var newShares = Math.floor(recentShares * (Math.random() + article.get('site.credibility') / 800)) + trendingTopicMatches.length * 15 + famousShareBonus * 100;
                var newViews = Math.floor(newShares * Math.random() * 5);

                if (debunked) {
                  newViews = newViews * 1.5;
                  newShares = newShares / 2;
                }

                if (famousShareBonus > 0) {
                  famousShareBonus -= 5;
                  if (famousShareBonus < 0) {
                    famousShareBonus = 0;
                  }
                  article.set('famousShareBonus', famousShareBonus);
                }

                var newLikes = Math.floor(newShares * Math.random() * 5);
                var newRevenue = Math.floor(newViews * Math.random()) / 200;

                article.set('recentShares', newShares);

                this.updateArticleProperty(article, 'likes', newLikes);
                this.updateArticleProperty(article, 'shares', newShares);
                this.updateArticleProperty(article, 'views', newViews);
                this.updateArticleProperty(article, 'revenue', newRevenue);

                if (!hideSpecialEvents) {
                  this.checkSpecialEvents(article, debunked, totalShares, trendingTopicMatches);
                }
              } else if (totalShares > 1000) {
                var newLikes = Math.floor(Math.random() * 5);
                var newShares = Math.floor(Math.random() * 2);
                var newViews = Math.floor(Math.random() * 2);
                var newRevenue = Math.floor(newViews * Math.random()) / 100;

                this.updateArticleProperty(article, 'likes', newLikes);
                this.updateArticleProperty(article, 'shares', newShares);
                this.updateArticleProperty(article, 'views', newViews);
                this.updateArticleProperty(article, 'revenue', newRevenue);
              } else if (totalShares > 10) {
                var newLikes = Math.floor(Math.random() + 0.2);
                var newShares = Math.floor(Math.random() + 0.05);
                var newViews = Math.floor(Math.random() + 0.1);
                var newRevenue = Math.floor(newViews * Math.random()) / 100;

                this.updateArticleProperty(article, 'likes', newLikes);
                this.updateArticleProperty(article, 'shares', newShares);
                this.updateArticleProperty(article, 'views', newViews);
                this.updateArticleProperty(article, 'revenue', newRevenue);
              }
            }, this);

            i = 0, x = plants.get('length');

          case 8:
            if (!(i < x)) {
              context$1$0.next = 54;
              break;
            }

            context$1$0.next = 11;
            return plants[i];

          case 11:
            plant = context$1$0.sent;
            context$1$0.next = 14;
            return plant.get('plantedTo');

          case 14:
            group = context$1$0.sent;
            context$1$0.next = 17;
            return group.get('totalNumbers');

          case 17:
            groupMembers = context$1$0.sent;
            context$1$0.next = 20;
            return plant.get('hoursSincePlanted');

          case 20:
            hoursSincePlanted = context$1$0.sent;
            groupMemberBonus = groupMembers / 200;

            if (groupMemberBonus > 20) {
              groupMemberBonus = 30;
            }

            timeDrag = 0;

            if (hoursSincePlanted > 6) {
              timeDrag = hoursSincePlanted / 6;
            }

            context$1$0.next = 27;
            return plant.get('likeFactor');

          case 27:
            likeFactor = context$1$0.sent;
            context$1$0.next = 30;
            return plant.get('shareFactor');

          case 30:
            shareFactor = context$1$0.sent;
            context$1$0.next = 33;
            return plant.get('viewFactor');

          case 33:
            viewFactor = context$1$0.sent;
            newLikes = Math.floor(Math.random() * likeFactor * groupMemberBonus - timeDrag * 20);
            newShares = Math.floor(Math.random() * shareFactor * groupMemberBonus / 2 - timeDrag * 20);
            newViews = Math.floor(viewFactor * Math.random() * groupMemberBonus / 2 - timeDrag * 20);
            newRevenue = Math.floor(newViews * Math.random()) / 100;

            if (plant.get('article.debunked')) {
              newViews = newViews * 1.5;
              newShares = newShares / 2;
            }

            if (newLikes < 0) {
              plant.set('active', false);
              plant.save();
            }

            //console.log("Hours: " + hoursSincePlanted + " New Likes: " + newLikes + " New Shares: " + newShares + " New Views: " + newViews + " New Revenue: " + newRevenue);

            plant.incrementProperty('hoursSincePlanted');

            if (!(newShares > 0)) {
              context$1$0.next = 47;
              break;
            }

            context$1$0.next = 44;
            return plant.get('article');

          case 44:
            article = context$1$0.sent;

            article.incrementProperty('recentShares', newShares);
            article.save();

          case 47:

            this.updatePlantProperty(plant, 'likes', newLikes);
            this.updatePlantProperty(plant, 'shares', newShares);
            this.updatePlantProperty(plant, 'views', newViews);
            this.updatePlantProperty(plant, 'revenue', newRevenue);

          case 51:
            i++;
            context$1$0.next = 8;
            break;

          case 54:
          case 'end':
            return context$1$0.stop();
        }
      }, callee$0$0, this);
    })),
    checkSpecialEvents: function checkSpecialEvents(article, debunked, totalShares, trendingTopicMatches) {
      var lotsOfShares = totalShares > 10000;
      var tonsOfShares = totalShares > 100000;
      if (article.get('couldGoViral') && !debunked && trendingTopicMatches.length > 0 && this.get('gameData.currentGame.famousShareOn')) {
        if (Math.random() > 0.97) {
          this.get('gameData').famousShare(article);
        }
      }
      if (!debunked && lotsOfShares) {
        var random = Math.random();
        if (random > 0.98) {
          this.get('gameData').debunkArticle(article);
        } else if (tonsOfShares) {
          if (random > 0.95) {
            this.get('gameData').debunkArticle(article);
          }
        }
      }

      if (lotsOfShares && !this.get('gameData.currentGame.hasDisplayedHateCrimes') && article.get('aboutDangerousPeople')) {
        if (Math.random() > 0.9) {
          this.get('gameData').textOnlySpecialEvent(article, "hate_crimes");
          this.set('gameData.currentGame.hasDisplayedHateCrimes', true);
          var currentDate = this.get('gameData.currentGame.date').toString();
          ga('send', 'event', 'Special Message', 'hate_crimes', currentDate);
        }
      } else if (lotsOfShares && !this.get('gameData.currentGame.hasDisplayedHealth') && article.get('aboutHealthDangers')) {
        if (Math.random() > 0.9) {
          this.get('gameData').textOnlySpecialEvent(article, "health_advice");
          this.set('gameData.currentGame.hasDisplayedHealth', true);
          var currentDate = this.get('gameData.currentGame.date').toString();
          ga('send', 'event', 'Special Message', 'health_advice', currentDate);
        }
      } else if (lotsOfShares && !this.get('gameData.currentGame.hasDisplayedCrime') && article.get('aboutCrimeIncreasing')) {
        if (Math.random() > 0.9) {
          this.get('gameData').textOnlySpecialEvent(article, "crime_perception");
          this.set('gameData.currentGame.hasDisplayedCrime', true);
          var currentDate = this.get('gameData.currentGame.date').toString();
          ga('send', 'event', 'Special Message', 'crime_increasing', currentDate);
        }
      } else if (tonsOfShares && !this.get('gameData.currentGame.hasDisplayedPersonGotFired') && article.get('potentialToGetFired')) {
        if (Math.random() > 0.9) {
          this.get('gameData').textOnlySpecialEvent(article, "person_got_fired");
          this.set('gameData.currentGame.hasDisplayedPersonGotFired', true);
          var currentDate = this.get('gameData.currentGame.date').toString();
          ga('send', 'event', 'Special Message', 'person_got_fired', currentDate);
        }
      }
    }

  });
});
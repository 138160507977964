define("fakenews/templates/components/site-dashboard", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "fakenews/templates/components/site-dashboard.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h2");
        dom.setAttribute(el1, "class", "ui header");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("img");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "content");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "sub header");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "sub header");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "ui horizontal list");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element0, [3]);
        var element3 = dom.childAt(element2, [3]);
        var element4 = dom.childAt(element0, [5, 1]);
        var morphs = new Array(8);
        morphs[0] = dom.createAttrMorph(element1, 'src');
        morphs[1] = dom.createMorphAt(element2, 1, 1);
        morphs[2] = dom.createMorphAt(element3, 1, 1);
        morphs[3] = dom.createMorphAt(element3, 3, 3);
        morphs[4] = dom.createMorphAt(element4, 1, 1);
        morphs[5] = dom.createMorphAt(element4, 3, 3);
        morphs[6] = dom.createMorphAt(element4, 5, 5);
        morphs[7] = dom.createMorphAt(element4, 7, 7);
        return morphs;
      },
      statements: [["attribute", "src", ["subexpr", "concat", ["../../../../images/", ["get", "site.logo", ["loc", [null, [2, 42], [2, 51]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [2, 53]]], 0, 0], 0, 0, 0, 0], ["content", "site.name", ["loc", [null, [4, 4], [4, 17]]], 0, 0, 0, 0], ["inline", "attribute-label", [], ["text", ["subexpr", "t", ["labels.articles"], [], ["loc", [null, [6, 29], [6, 50]]], 0, 0], "value", ["subexpr", "@mut", [["get", "site.totalArticles", ["loc", [null, [6, 57], [6, 75]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 6], [6, 77]]], 0, 0], ["inline", "attribute-label", [], ["text", ["subexpr", "t", ["labels.credibility"], [], ["loc", [null, [6, 101], [6, 125]]], 0, 0], "value", ["subexpr", "@mut", [["get", "site.credibility", ["loc", [null, [6, 132], [6, 148]]], 0, 0, 0, 0]], [], [], 0, 0], "max", 100], ["loc", [null, [6, 78], [6, 158]]], 0, 0], ["inline", "icons/likes-with-popup", [], ["likes", ["subexpr", "@mut", [["get", "site.likes", ["loc", [null, [12, 37], [12, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "ui item"], ["loc", [null, [12, 6], [12, 65]]], 0, 0], ["inline", "icons/shares-with-popup", [], ["shares", ["subexpr", "@mut", [["get", "site.shares", ["loc", [null, [13, 39], [13, 50]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "ui item"], ["loc", [null, [13, 6], [13, 68]]], 0, 0], ["inline", "icons/views-with-popup", [], ["views", ["subexpr", "@mut", [["get", "site.views", ["loc", [null, [14, 37], [14, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "ui item"], ["loc", [null, [14, 6], [14, 65]]], 0, 0], ["inline", "icons/revenue-with-popup", [], ["revenue", ["subexpr", "@mut", [["get", "site.revenue", ["loc", [null, [15, 41], [15, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "ui item"], ["loc", [null, [15, 6], [15, 71]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});
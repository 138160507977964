define("fakenews/components/domain-hosting-options", ["exports", "ember"], function (exports, _ember) {
  exports["default"] = _ember["default"].Component.extend({
    classNames: ["ui", "link", "three", "cards"],

    domainAndHostingOptions: [],

    didReceiveAttrs: function didReceiveAttrs() {
      var self = this;
      _ember["default"].$.getJSON('../../../data/domainAndHostingOptions.json').then(function (data) {
        var domainAndHostingOptions = data.uniq();
        self.set('domainAndHostingOptions', domainAndHostingOptions);
        self.updateOptions();
      });
    },
    updateOptions: function updateOptions() {
      var purchasedItemKeys = this.get('gameData.currentGame.purchasedSiteItems');
      var domainAndHostingOptions = this.get('domainAndHostingOptions');
      domainAndHostingOptions.forEach(function (option) {
        if (purchasedItemKeys.includes(option.key)) {
          _ember["default"].set(option, 'description', option.alreadyPurchasedDescription);
          _ember["default"].set(option, 'monthlyCosts', 0);
        } else if (option.monthlyCosts > this.get('gameData.currentGame.revenue') && option.monthlyCostsCost > 0 || option.oneTimeCost > this.get('gameData.currentGame.revenue') && option.oneTimeCost > 0) {
          _ember["default"].set(option, 'tooExpensive', true);
        }
        if (_ember["default"].isPresent(this.get('site'))) {
          if (option.key === this.get('site.domainAndHosting.key')) {
            _ember["default"].set(option, 'selected', true);
          }
        }
      }, this);
      this.set('domainAndHostingOptions', domainAndHostingOptions);
    },
    actions: {
      optionSelected: function optionSelected(option) {
        if (!option.tooExpensive) {
          this.get('domainAndHostingOptions').forEach(function (option) {
            _ember["default"].set(option, 'selected', false);
          });

          var site = this.get('site');

          site.set('domainAndHosting', option);
          site.save();

          this.sendAction('domainAndHostingOptionSelected', option);
          this.updateOptions();
        }
      }
    }
  });
});